<template>
  <div>

      <Header title="Choix de mon groupe" />

      <div class="page-content-wrapper">

        <div class="container mb-3">
          <div class="alert bg-light rounded">
            <i class="fas fa-question-circle me-2"></i>
            Vous participez à plusieurs groupes RBO
            <br/>veuillez sélectionner un groupe...
          </div>
        </div>

        <div class="container mb-3">
          <div class="card"> 
            <div class="card-body">
              <h4 class="mb-2">{{ groupCurrent.name }}</h4>
              <a class="btn btn-rbo w-100" v-bind:href="'profile'">Rester sur ce groupe</a>
            </div>
          </div>
        </div>

        <div v-for="group in groups" :key="group.id" class="container mb-3">
          <div class="card"> 
            <div class="card-body">
              <h4 class="mb-2">{{ group.name }}</h4>
              <a class="btn btn-rbo w-100" href="#" v-on:click="changeGroup(currentMemberId, group.id)">Choisir ce groupe</a>
            </div>
          </div>
        </div>

      </div>

      <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'GroupChoice',
  data: function() {
    return {
      currentMemberId: null,
      groupCurrent: [],
      groups: []
    }
  },
  components: {
    Header,
    Footer
  },
  created() {
    let lsUserData = JSON.parse(localStorage.getItem('userData'));
    this.currentMemberId = lsUserData.member.id;
    if(lsUserData.members.length > 0) {
      this.groupCurrent = {'id':lsUserData.groupe.id,'name':lsUserData.groupe.name};
      lsUserData.members.forEach(item => {
        this.groups.push(item.groupe);
      });
    } else {
      this.$router.push('/home');
    }
  },
  methods: {
    async changeGroup(memberId, groupId) {
      await UserService.getChangeGroup(memberId, groupId).then(() => {
          
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.push('/profile');
    }
  }
}
</script>