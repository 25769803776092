<template>
  <div>

    <ul v-if="members.length > 0" class="ps-0 chat-user-list">
      <li v-for="member in members" :key="member.id" class="px-3 pt-3 pb-2 chat-unread text-left">
        <a class="d-flex" v-bind:href="'/members/' + member.id">
          <div class="chat-user-thumbnail me-4 shadow">
            <img v-if="member.contact.photo" v-bind:src="urlUploads + member.contact.photo" class="avatar-img rounded-circle" alt="photo">
            <img v-else src="/img/avatar.png" class="avatar-img rounded-circle" alt="photo">
          </div>
          <div class="chat-user-info">
            <h6 class="text-truncate mb-0">{{member.contact.firstName}} {{member.contact.lastName}}</h6>
            <div class="last-chat">
              <p class="mb-0">
                <span v-if="member.contact.jobTitle">{{member.contact.jobTitle}}, </span>
                <span v-if="member.contact.organisme.enseigne">{{member.contact.organisme.enseigne}} - </span> {{member.contact.organisme.name}}
              </p>
              <p v-if="member.contact.mobilePhone" class="mb-0">
                {{member.contact.mobilePhone}}
              </p>
              <p v-if="isSearch" class="mb-0">
                Groupe : {{member.groupe.name}}
              </p>
            </div>
          </div>
        </a>
        <div class="dropstart chat-options-btn">
          <a v-bind:href="'/members/' + member.id" class="text-rbo">
            <i class="fas fa-chevron-right"></i>
          </a>
        </div>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: 'MembersList',
  props: {
    members: Array,
    isSearch: Boolean,
    urlUploads: String
  }
}
</script>