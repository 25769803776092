<template>
  <div id="remForm">
        <div class="card mb-3">
          <div class="card-body">

            <!-- rem form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center text-rbo">{{ editing ? 'Modifier' : 'Enregistrer' }} une R.E.M</h6>

              <FormulateForm @submit="validationForm">
                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date"
                    format="dd/MM/yyyy"
                    :disabled-dates="tomorrow.disabledDates"
                    v-model="rem.date"
                />

                <div class="text-left mb-1"><label >Entre</label></div>
                <Multiselect 
                  v-model="rem.member1" 
                  :options="formSelectMember1"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectMember1.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le nom du membre"
                  :internal-search="false"
                  @search-change="searchChangeMember1"
                ></Multiselect>

                <div class="text-left mt-4 mb-1"><label>Et</label></div>
                <Multiselect
                  v-model="rem.member2" 
                  :options="formSelectMembers"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectMembers.label"
                  label="label"
                  track-by="value"
                  placeholder="Selectionner le nom du membre"
                  :internal-search="false"
                  @search-change="searchChangeMember2"
                ></Multiselect>

                <div class="mb-4"></div>

                <!--
                <FormulateInput
                    :options=formSelectMember1
                    type="autocomplete"
                    name="member1"
                    validation="required"
                    :validation-messages="{
                      required: 'Veuillez indiquer le nom du membre...'
                    }" 
                    label="Entre"
                    :value="userData.contact.fullName + ' (' + userData.groupe.name + ')'"
                    v-model="rem.member1"
                />

                <FormulateInput
                    :options=formSelectMembers
                    type="autocomplete"
                    name="member2"
                    placeholder="Indiquer le nom du membre"
                    validation="required"
                    :validation-messages="{
                      required: 'Veuillez indiquer le nom du membre...'
                    }" 
                    label="Et"
                    error-behavior="submit"
                    v-model="rem.member2"
                />
                -->

                <image-uploader
                  :debug="1"
                  :maxWidth="900"
                  :quality="0.8"
                  :autoRotate=true
                  outputFormat="verbose"
                  :preview=true
                  :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                  :capture="false"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  @input="setImage"
                  @onUpload="startImageResize"
                  @onComplete="endImageResize"
                ></image-uploader>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonRem"
                  input-class="btn btn-rbo w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
import router from '../router'
import UserService from '../services/user.service'
import ImageUploader from 'vue-image-upload-resize'
import Multiselect from 'vue-multiselect'

var temp = Object.freeze({
  member1: '',
  member2: '',
  selfie: '',
  hasImage: false,
  image: null,
  photoBase64: null
});

export default {
  name: 'Form',
  components: {
    ImageUploader,
    Multiselect,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      formSelectMember1: [],
      formSelectMembers: [],
      error: null,
      rem: Object.assign({}, temp),
      editing: false,
      remNewId: null,
      tomorrow: null,
      member2Id: null,
      isBureau: false,
      isDDD: false,
      isRBO: false,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
        await UserService.getMembersOtherForm().then(
        response => {
          localStorage.setItem('membersForm', JSON.stringify(response.data));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    this.userGroupMembers = JSON.parse(localStorage.getItem('membersForm'));

    const lsMembersForm = JSON.parse(localStorage.getItem('membersForm'));
    if( lsMembersForm ) { this.formSelectMembers = lsMembersForm['hydra:member']; } 

    if(localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'));
        // isBureau
        if( ['ROLE_BUREAU'].includes(this.userData.user.role) ) {
          this.isBureau = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isDDD
        else if( ['ROLE_DDD', 'ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isDDD = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isRBO or more
        else if( ['ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isRBO = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isMember
        else {
          this.formSelectMember1 =[{ value: this.userData.member.id, label: this.userData.contact.fullName }];
        }
    }
    this.tomorrow = {
      disabledDates: {
        from: new Date(),
      },
    }
  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.rem = Object.assign({}, temp);
      }
    }
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.getRemById();
      this.editing = true;
    } else {
      this.rem.date = new Date();
      this.rem.member1 = this.userData.member ? {value : this.userData.member.id, label: this.userData.contact.fullName + ' (' + this.userData.groupe.name + ')'} : '';
    }
  },
  methods: {
    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },
    searchChangeMember1(search) {
      if(this.isBureau || this.isDDD || this.isRBO ) {
        this.formSelectMember1 = search
          ? (this.formSelectMember1.filter(option => this.normalizedContains(search, option.label)))
          : this.userGroupMembers['hydra:member'];
      } else {
         this.formSelectMember1 = search
        ? (this.formSelectMember1.filter(option => this.normalizedContains(search, option.label)))
        : [{ value: this.userData.member.id, label: this.userData.contact.fullName }];
      }
    },
    searchChangeMember2(search) {
      this.formSelectMembers = search
        ? (this.formSelectMembers.filter(option => this.normalizedContains(search, option.label)))
        : this.userGroupMembers['hydra:member'];
    },
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
    },
    getRemById() {
      UserService.getRem(this.$route.params.id).then( 
        response => {
          this.rem = response.data;
          this.rem.member1.label = this.rem.member1.contact.firstName + ' ' + this.rem.member1.contact.lastName + ' (' + this.rem.member1.groupe.name + ')';
          this.rem.member1.value = this.rem.member1.id;
          /*
          this.rem.member1.value = this.rem.member1;

          var valueMember2 = document.getElementsByName('member2');
          valueMember2[0].id = this.rem.member2.id;
          this.member2Id = valueMember2[0].id
          this.rem.member2 = this.rem.member2.contact.firstName + ' ' + this.rem.member2.contact.lastName + ' (' + this.rem.member2.groupe.name + ')';       
          */
         this.rem.member2.label = this.rem.member2.contact.firstName + ' ' + this.rem.member2.contact.lastName + ' (' + this.rem.member2.groupe.name + ')';
         this.rem.member2.value = this.rem.member2.id;
        },
        error => {this.content =(error.response && error.response.data) || error.message || error.toString();}
      );
    },
    async reload() {
       await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          let lsUserData = JSON.parse(localStorage.getItem('userData'));
          this.$store.dispatch('auth/setIsMember', lsUserData.member.length > 0 ? true : false);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
        if("id" in this.$route.params) {
            const rem = {
              member1: "/api/members/" + this.rem.member1.value,
              member2: "/api/members/" + this.rem.member2.value,
              photoBase64: this.image != null ? this.image.dataUrl : null,
              date: this.rem.date,
              updatedBy: "/api/users/" + this.userData.user.id,
            };
            UserService.patchRem(this.$route.params.id, rem).then(
              () => {
                UserService.getUserMe().then(
                  response => {
                    localStorage.setItem('userData', response.data);
                  },
                  error => {
                    this.content = (error.response && error.response.data) || error.message || error.toString();
                  }
                );
              },
            );
            document.getElementById('buttonRem').click();
            document.getElementById('closeModalRem').click();
            router.push('/rems');
            this.reload();
            
        } else {
          //var valueMember2 = document.getElementsByName('member2');
          //var valueMember1 = document.getElementsByName('member1');
            const rem = {
              site: "/api/sites/" + this.userData.user.siteId,
              groupe: "/api/groupes/" + this.userData.groupe.id,
              //member1: "/api/members/" + (valueMember1[0].id ? valueMember1[0].id : this.userData.member.id),
              //member2: "/api/members/" + valueMember2[0].id,
              member1: "/api/members/" + this.rem.member1.value,
              member2: "/api/members/" + this.rem.member2.value,
              photoBase64: this.image != null ? this.image.dataUrl : null,
              date: this.rem.date,
              createdBy: "/api/users/" + this.userData.user.id,
            };
            await UserService.postRem(rem).then(
              res => {
                let remId = res.data['@id'];
                this.remNewId = remId.slice(10);
                  UserService.getUserMe().then(
                    response => {
                      localStorage.setItem('userData', response.data);
                    },
                    error => {
                      this.content = (error.response && error.response.data) || error.message || error.toString();
                    }
                  );
              },
            );
            router.push('/rems/' + this.remNewId);
            this.reload();
        }
    },  
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

