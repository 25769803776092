<template>
  <div>
    <!-- statut -->
        <span v-if="statut == 'proposed'" class="badge bg-warning">Proposée</span>
        <span v-if="statut == 'accepted'" class="badge bg-success">Acceptée</span>
        <span v-if="statut == 'denied'" class="badge bg-secondary">Refusée</span>
  </div>
</template>

<script>

export default {
  name: 'InviteStatut',
  props: ['statut'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>