<template>
  <div>

    <Header title="Groupe" />

    <div class="page-content-wrapper">

      <div class="container">
        <div class="card">
          <div class="card-body">
            <div class="minimal-tab">
              <ul v-if="(loggedIn && isDDD) || (isBureau && loggedIn)" class="nav nav-tabs mb-3" id="affanTab2" role="tablist">
                <li v-if="(loggedIn && isDDD) || (isBureau && loggedIn)" class="nav-item" role="presentation">
                  <button v-bind:class="loggedIn?'btn active':'btn'" id="myGroup-tab" data-bs-toggle="tab" data-bs-target="#group" type="button" role="tab" aria-controls="myGroup" aria-selected="true">Informations</button>
                </li>
                <li v-if="(loggedIn && isDDD) || (isBureau && loggedIn)" class="nav-item" role="presentation">
                  <button class="btn" id="reunions-tab" data-bs-toggle="tab" data-bs-target="#reunions" type="button" role="tab" aria-controls="reunions" aria-selected="false">Réunions</button>
                </li>
              </ul>
              <div class="tab-content rounded" id="affanTab2Content">

                <!-- Mon groupe -->
                <div v-if="loggedIn" v-bind:class="loggedIn?'tab-pane show active':'tab-pane'" id="group" role="tabpanel" aria-labelledby="myGroup-tab">
                  <div class="container mx-0 px-0 mb-3">
                    <div>
                      <div class="card rounded-0 rounded-top">
                        <div class="card-body">
                           <span v-if="group.statut == 'project'" class="badge bg-warning p-1 mb-2" style="">En projet</span>
                           <h3 class="text-rbo mb-3">{{ group.name }}</h3>
                           <img v-if="group.blason" v-bind:src="this.$urlUploads + group.blason" class="img-fluid mb-0" alt="blason">
                        </div>
                      </div>
                      
                        <!-- infos du groupe -->
                        <div v-if="!group.isCorporate" class="container mb-3">
                          <div class="card bg-rbo rounded-0 rounded-top">
                            <div class="card-body text-center py-3">
                              <h6 class="mb-0 text-white">Informations</h6>
                            </div>
                          </div>
                          <div v-if="!group.isCorporate" class="card rounded-0 rounded-bottom">
                            <ul class="list-group">
                              <li class="list-group-item">Réunion chaque {{ group.jourReunion }} à {{ group.heureDebut }}</li>
                              <li class="list-group-item"><SocialNetwork :f="group.facebook" :i="group.instagram" :l="group.linkedIn" /></li>
                            </ul>
                          </div>
                        </div>

                        <!-- dashboard du groupe -->
                        <div v-if="isDDD || isBureau" class="container">
                          <div class="card mb-3">
                            <div class="card bg-rbo rounded-0 rounded-top">
                              <div class="card-body py-3">
                                <h6 class="mb-0 text-white line-height-1">Tableau de bord</h6>
                              </div>
                            </div>
                            <div class="card rounded-0 rounded-bottom">
                              <ul class="list-group">
                                <li class="list-group-item d-flex align-items-center justify-content-between">
                                  <div><i class="fas fa-utensils" style="padding-right:1rem;"></i>R.E.M</div>
                                  <span class="badge rounded-pill" v-bind:class="rems ? 'bg-success' : 'bg-warning'">{{rems ? rems : '0'}}</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between">
                                  <div><i class="fas fa-exchange-alt" style="padding-right:0.8rem;"></i>M.I.R.E</div>
                                  <span class="badge rounded-pill" v-bind:class="mires ? 'bg-success' : 'bg-warning'">{{mires ? mires : '0' }}</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between">
                                  <div><i class="fas fa-hand-holding-usd" style="padding-right:0.7rem;"></i>Merci CA</div>
                                  <span class="badge rounded-pill" v-bind:class="merciCaNb ? 'bg-success' : 'bg-warning'">{{merciCaNb ? merciCaNb : '0' }}</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between">
                                  <div><i class="fas fa-hand-holding-usd" style="padding-right:0.7rem;"></i>Merci CA</div>
                                  <span class="badge rounded-pill" v-bind:class="merciCaTotal ? 'bg-success' : 'bg-warning'">{{merciCaTotal|formatDecimal }} €HT</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between">
                                  <div><i class="fas fa-handshake" style="padding-right:0.6rem;"></i>Invités</div>
                                  <span class="badge rounded-pill" v-bind:class="guest ? 'bg-success' : 'bg-warning'">{{guest ? guest : '0' }}</span>
                                </li>
                                <li class="list-group-item d-flex align-items-center justify-content-between">
                                  <div><i class="fas fa-users" style="padding-right:0.6rem;"></i>Membres</div>
                                  <span class="badge rounded-pill" v-bind:class="membersTotal ? 'bg-success' : 'bg-warning'">{{membersTotal ? membersTotal : '0' }}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <!-- les membres du groupe -->
                        <div v-if="isDDD || isBureau" class="container mb-3">
                          <div class="card bg-rbo rounded-0 rounded-top">
                            <div class="card-body text-center py-3">
                              <h6 class="mb-0 text-white">Les membres du groupe</h6>
                            </div>
                          </div>
                          <div class="card rounded-0 rounded-bottom">
                            <MembersList v-bind:members="members" v-bind:urlUploads="urlUploads" />
                          </div>
                        </div>
      
                    </div>
                  
                  </div>

                </div>

                
                <!-- Réunions (tab) -->
                <div v-if="(loggedIn && isDDD) || (isBureau && loggedIn)" class="tab-pane fade" id="reunions" role="tabpanel" aria-labelledby="reunions-tab">
                  <div class="container mx-0 px-0 mb-3">
                    <div v-if="reunions.length > 0 ">
                      <div class="card bg-rbo rounded-0 rounded-top">
                        <div class="card-body py-3">
                          <h6 class="mb-0 text-white line-height-1">Les 10 prochaines réunions</h6>
                        </div>
                      </div>
                      <div class="card rounded-0 rounded-bottom">
                        <ul class="ps-0 chat-user-list mb-0">
                          <li v-for="reunion in reunions" :key="reunion.id" class="p-3 mb-0 chat-unread">
                            <a class="d-flex" v-bind:href="'/reunions/' + reunion.id">
                              <div class="chat-user-info">
                                <h6 class="text-left text-truncate mb-0">Le {{reunion.date|formatDate}} à {{reunion.heureDebut}}</h6>
                                <div class="last-chat">
                                  <p v-if="reunion.ordreDuJour" class="text-left mb-0 text-truncate">Ordre du jour : {{reunion.ordreDuJour}}</p>
                                  <p v-if="reunion.isDone" class="text-left mb-0 text-truncate"><span class="badge bg-success">Clôturée</span></p>
                                </div>
                              </div>
                            </a>
                            <div class="dropstart chat-options-btn">
                              <a v-bind:href="'/reunions/' + reunion.id" class="text-rbo">
                                <i class="fas fa-chevron-right"></i>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-else class="card bg-danger rounded-0 rounded">
                      <div class="card-body text-center py-3">
                        <h6 class="mb-0 text-white line-height-1">Aucune réunion</h6>
                      </div>
                    </div>
                  </div>
                </div>
               


              </div>
            </div>
          </div>
        </div>
      </div>




    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SocialNetwork from '@/components/SocialNetwork.vue'
import MembersList from '@/components/MembersList.vue'
import UserService from '../services/user.service';

export default {
  name: 'Group',
  data: function() {
    return {
      group: [],
      userData: [],
      members: [],
      reunions: [],
      isDDD: false,
      isBureau: false,
      rems: 0,
      mires: 0,
      mercica: [],
      merciCaNb: 0,
      merciCaTotal: 0,
      guest: 0,
      membersTotal: 0,
      urlUploads: this.$urlUploads,
      loggedIn: this.$store.state.auth.status.loggedIn,
    }
  },
  components: {
    Header,
    Footer,
    SocialNetwork,
    MembersList
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    },
    formatDecimal: function (value) {
      if (!value) return '0'
      value = (value).toFixed(2);
      return value;
    }
  }, 
  async created() {
    // groupe
    await UserService.getGroup(this.$route.params.id).then((response) => {
        localStorage.setItem('group', JSON.stringify(response.data));
        this.group = response.data;  
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    if(localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'));

        // isDDD (or more)
        if( ['ROLE_CDG','ROLE_DDD', 'ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isDDD = true;
        }
        // isBureau
        if( ['ROLE_BUREAU'].includes(this.userData.user.role) ) {
          this.isBureau = true;
        }
    }

    // rems
    await UserService.getRems('?gId=' + this.$route.params.id + '&date[after]=2022-01-01').then(
        response => {
          this.rems = response.data['hydra:totalItems'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    
    // mires
    await UserService.getMires('?gId=' + this.$route.params.id + '&date[after]=2022-01-01').then(
        response => {
          this.mires = response.data['hydra:totalItems'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    
    // merciCa
    await UserService.getMerciCas('?gId=' + this.$route.params.id + '&date[after]=2022-01-01').then(
        response => {
          this.merciCaNb = response.data['hydra:totalItems'];
          this.mercica = response.data['hydra:member'];
          // merciCa ca total
          this.mercica.forEach(element => {
            let ca = JSON.parse(element.ca);
            this.merciCaTotal += ca;
          }, this);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

    //guests
    await UserService.getReunionEvents('?gId=' + this.$route.params.id + '&t=guest').then(
        response => {
          this.guest = response.data['hydra:totalItems'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );  

    // members
    await UserService.getMembersOtherGroup('?gId=' + this.$route.params.id).then(
        response => {
          this.members = response.data['hydra:member'];
          this.membersTotal = response.data['hydra:totalItems'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

    // les 8 prochaines réunions du groupe
    await  UserService.getReunions('?gId=' + this.$route.params.id).then(
        response => {
          this.reunions = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      )

  }

}
</script>
