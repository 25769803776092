<template>
  <div>

    <Header title="Interactions" />

    <div class="page-content-wrapper">

      <div class="container">
        <div class="card mb-3">
          <div class="card-body">

            <!-- logo -->
            <div class="pb-3">
              <img src="/img/rbo/logo.png" class="w-60" alt="logo">
            </div>

            <div class="pb-3"></div>

            <div class="text-justify">
              <p>
                  Une "M.I.R.E" correspond à une "Mise en relation" entre deux membres de RBO (M.I.R.E interne) ou entre un membre RBO et un de ses contacts (M.I.R.E externe).
              </p>
            </div>

            <router-link :to="{ path: '/mires/formulaire', query: { type: 'add' } }" class="btn btn-rbo w-100">Enregistrer une M.I.R.E</router-link>

            <div class="pb-3"></div>

            <div class="text-justify">
              <p>
                  Une "R.E.M" correspond à une "Rencontre entre membres" ... faisons connaissance entre membres RBO !
              </p>
            </div>
             <router-link :to="{ path: '/rems/formulaire', query: { type: 'add' } }" class="btn btn-rbo w-100">Enregistrer une R.E.M</router-link>

            <div class="pb-3"></div>

            <div class="text-justify">
              <p>
                   Un "MERCI CA" correspond à enregistrer une affaire obtenue grâce au réseau RBO (suite à une M.I.R.E ou une R.E.M)
              </p>
            </div>

              <router-link :to="{ path: '/merciCas/formulaire', query: { type: 'add' } }" class="btn btn-rbo w-100">Enregistrer un Merci CA</router-link>


          </div>
        </div>
      </div>
    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Interactions',
  components: {
    Header,
    Footer
  }
}
</script>

<style>
.w-60 {
  width: 60%;
}
</style>