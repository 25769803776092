<template>
  <div>
        <div class="card mb-3">
          <div class="card-body">

            <!-- invite form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center text-rbo">Enregistrer un invité</h6>

              <form @submit.prevent="validationForm">
                <FormulateInput
                :options=formSelectReunions
                    type="select"
                    name="reunion"
                    label="Réunion"
                    validation="required"
                    :validation-messages="{
                      required: 'Selectionner une réunion'
                    }" 
                    :value=formSelectReunions[0].value
                    v-model="guest.reunion"
                />
                <FormulateInput
                    :options="[
                        { value: userData.member.id, label: userData.contact.fullName }
                    ]"
                    type="select"
                    name="member"
                    validation="required"
                    label="Invité par"
                    disabled=disabled
                    :value="userData.member.id"
                    v-model="guest.member"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Nom du contact"
                  placeholder="Saisisser le nom du contact"
                  validation="required"
                  :validation-messages="{
                      required: 'Le nom du contact est obligatoire'
                    }"
                  v-model="guest.contactLastName"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Prénom du contact"
                  placeholder="Saisisser le prénom du contact"
                  v-model="guest.contactFirstName"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Société du contact"
                  placeholder="Saisisser le nom de la société du contact"
                  v-model="guest.contactCompany"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Métier du contact"
                  placeholder="Saisisser le métier du contact"
                  v-model="guest.contactJobTitle"
                />
                <FormulateInput
                  type="tel"
                  name="phone"
                  label="Tél. mobile"
                  placeholder="Saisisser le téléphone du contact"
                  v-model="guest.contactTel"
                />
                <FormulateInput
                  type="email"
                  name="sample"
                  label="Courriel"
                  placeholder="Saisisser l'email du contact"
                  validation="required"
                  :validation-messages="{
                      required: 'L\'email du contact est obligatoire'
                    }"
                  v-model="guest.contactEmail"
                />

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonGuest"
                  input-class="btn btn-rbo w-100 text-white mt-3"
                />
                
              </form>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
import router from '../router'
import UserService from '../services/user.service';

var temp = Object.freeze({
      reunion: '',
      member: '',
      contactLastName: '',
      contactFirstName: '',
      contactCompany: '',
      contactTel: '',
      contactEmail: '',
      contactJobTitle: '',
    });

export default {
  name: 'Form',
  components: {
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      error: null,
      guest: Object.assign({}, temp),
      formSelectReunions: [],
      editing: false,
      urlUploads: this.$urlUploads,
      isDDD: false,
    }
  },
  async created() {
    if(localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'));
        // isDDD (or more)
        if( ['ROLE_DDD', 'ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isDDD = true;
        }
    }

    if("id" in this.$route.params) {
      await UserService.getReunion(this.$route.params.id).then(
        response => {
          let reunion = response.data;
          this.formSelectReunions.push({ value: reunion.id, label: 'Réunion du ' + new Date(reunion.date).toLocaleDateString() + ' (' + reunion.groupe.name + ')'});
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      )
    } else {
      if(this.isDDD) {
            await UserService.getUserMyGroups().then(
              response => {
                localStorage.setItem('userGroups', response.data);
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            );
        
          this.userGroups = JSON.parse(localStorage.getItem('userGroups'));
          await this.userGroups.groups.forEach(element => {
            // les 8 prochaines réunions du groupe
            UserService.getReunions('?gId=' + element.id).then(
              response => {
                let reunions = response.data['hydra:member'];
                reunions.forEach(reunion => {
                  this.formSelectReunions.push({ value: reunion.id, label: 'Réunion du ' + new Date(reunion.date).toLocaleDateString() + ' (' + reunion.groupe.name + ')'});
                });
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            )
          }, this);
    } else {
      this.userData.reunions.forEach(reunion => {
      this.formSelectReunions.push({ value: reunion.id, label: 'Réunion du ' + reunion.date});
      });
    }
    }



    
  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.guest = Object.assign({}, temp);
      }
    }
  },

  mounted() {
   
  },
  methods: {
    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          let lsUserData = JSON.parse(localStorage.getItem('userData'));
          this.$store.dispatch('auth/setIsMember', lsUserData.member.length > 0 ? true : false);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
       
          const guest = {
                reunion: "/api/reunions/" + this.guest.reunion,
                member: "/api/members/" + this.userData.member.id,
                isGuest: true,
                contactLastName: this.guest.contactLastName,
                contactFirstName: this.guest.contactFirstName,
                contactCompany: this.guest.contactCompany,
                contactTel: this.guest.contactTel,
                contactEmail: this.guest.contactEmail,
                contactJobTitle: this.guest.contactJobTitle,
                createdBy: "/api/users/" + this.userData.user.id,
            };
            
            await UserService.postReunionEvent(guest).then(
                res => {
                  let guestId = res.data['@id'];
                  this.guestNewId = guestId.slice(20);
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
                
            );
            document.getElementById('closeModalGuest').click();
            router.push('/invites/' + this.guestNewId);
            this.reload();
        }
    },  
  
}
</script>

