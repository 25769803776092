<template>
  <div>

    <Header title="Invité" />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left"></i> Mon Espace Membre</a></li>
            <li class="breadcrumb-item"><a href="/invites">Liste de mes invités</a></li>
          </ol>
        </nav>
      </div>

      <div class="container">
        <div class="card mb-3 user-info-card">
          <div class="card-body d-flex align-items-center">
            <div class="user-profile me-3">
              <img v-if="contact.photo" v-bind:src="this.$urlUploads + contact.photo" class="avatar-img rounded-circle" alt="photo">
              <img v-else src="/img/avatar.png" class="avatar-img rounded-circle" alt="photo">
            </div>
            <div class="user-info">
              <div class="d-flex">
                <h5 class="mb-1">{{contact.firstName}} {{contact.lastName}}</h5>
              </div>
              <p class="d-flex mb-0"><strong>{{organisme.name}}</strong></p>
            </div>
          </div>
        </div>
      </div>

      <!-- company 
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Son entreprise</h6>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><strong>{{organisme.name}}</strong></li>
            <li class="list-group-item text-justify">
              Activités :
              <span v-if="organisme.detail">{{organisme.detail}}</span>
              <span v-if="!organisme.detail" class="badge bg-warning text-dark">Non renseigné</span>
              </li>
            <li class="list-group-item">
              Site web : 
              <a v-if="organisme.siteWeb" :href="organisme.siteWeb" target="_blank">{{organisme.siteWeb}}</a>
              <span v-if="!organisme.siteWeb" class="badge bg-warning text-dark">Non renseigné</span>
            </li>
          </ul>
        </div>
      </div>
      -->
      
      <!-- infos -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Informations</h6>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">

            <!-- email -->
            <li class="list-group-item d-flex align-items-center justify-content-between">
              <div>
                <strong>Email : </strong>
                <strong v-if="contact.email">{{ contact.email }}</strong>
                <span v-if="!contact.email" class="badge bg-warning text-dark">Non renseigné</span>
              </div>
              <div v-if="contact.email">
                <a v-bind:href="'mailto:' + contact.email">
                <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;"><i class="fas fa-envelope"></i></span></a>
              </div>
            </li>

            <!-- mobilePhone -->
            <li class="list-group-item d-flex align-items-center justify-content-between">
              <div>
                <strong>Mobile : </strong>
                <strong v-if="contact.mobilePhone">{{ contact.mobilePhone }}</strong>
                <span v-if="!contact.mobilePhone" class="badge bg-warning text-dark">Non renseigné</span>
              </div>
              <div v-if="contact.mobilePhone">
                <a v-bind:href="'tel:' + contact.mobilePhone">
                  <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;"><i class="fas fa-phone-alt"></i></span></a>
                <a v-bind:href="'sms:' + contact.mobilePhone">
                  <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;">SMS</span></a>
              </div>
            </li>

            <!-- metier -->
            <li class="list-group-item d-flex align-items-center justify-content-between">
              <div>
                <strong>Métier : </strong>
                <strong v-if="contact.jobTitle">{{ contact.jobTitle }}</strong>
                <span v-if="!contact.jobTitle" class="badge bg-warning text-dark">Non renseigné</span>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <!-- invitations -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Invitations</h6>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="ps-0 chat-user-list rounded-0">
            <li class="rounded-0 px-3 pt-2 pb-1 text-left">
              <!--<a class="d-flex" v-bind:href="'/reunions/' + guest.reunion.id">-->
                <div class="chat-user-info w-100">
                  <h6 class="mt-2 mb-0">Invité à la réunion du {{guest.reunion.date|formatDate}} ({{guest.member.groupe.name}})</h6>
                  <div class="last-chat d-flex justify-content-between align-items-center">
                    <p class="mb-0">
                      par {{guest.member.contact.firstName}} {{guest.member.contact.lastName}}
                      
                    </p>
                    <Statut :statut="guest.guestStatut" />
                  </div>
                </div>
              <!--</a>
              <div class="dropstart chat-options-btn">
                <a v-bind:href="'/reunions/' + guest.reunion.id" class="text-rbo">
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>-->
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div class="pb-3"></div>
    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Statut from '@/components/InviteStatut.vue'
import UserService from '../services/user.service';

export default {
  name: 'Invite',
  data: function() {
    return {
      guest: [],
      contact: [],
      organisme: [],
      urlUploads: this.$urlUploads,
    }
  },
  components: {
    Header,
    Footer,
    Statut
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    await UserService.getReunionEvent(this.$route.params.id).then((response) => {
        this.guest = response.data;
        this.contact = this.guest.contact;
        this.organisme = this.contact.organisme;
        
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
  },
}
</script>