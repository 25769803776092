//import axios from "axios";
import axiosInstance from "./api";
import TokenService from "./token.service";

const setupInterceptors = (store) => {

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        //config.headers["x-access-token"] = token; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
  
      if (err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          
          originalConfig._retry = true;
  
          try {
            const rs = await refreshToken();

            //console.log('rs: ' + JSON.stringify(rs));
            store.dispatch('auth/refreshToken', rs);


            //const { rs } = rs.data;
            localStorage.setItem("user", JSON.stringify(rs.data));
            axiosInstance.defaults.headers.common["x-access-token"] = rs.data.token;
  
            return axiosInstance(originalConfig);

          } catch (_error) {
            if (_error.response && _error.response.data) {
              return Promise.reject(_error.response.data);
            }
  
            return Promise.reject(_error);
          }
        }
  
        if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }
      }
  
      return Promise.reject(err);
    }
  );

  function refreshToken() {
    return axiosInstance.post("/token/refresh", {
      refresh_token: TokenService.getLocalRefreshToken()
    });
  }

  /*
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      console.log('interceptor response started!');

      console.log('err.config : ' + JSON.stringify(err.config));

      console.log('originalConfig.url: ' + originalConfig.url);

      console.log('originalConfig._retry: ' + originalConfig._retry);

      if (originalConfig.url !== "/login" && err.response) {
        // Access Token was expired

        console.log('err.response.status: ' + err.response.status);

        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          console.log('originalConfig._retry: ' + originalConfig._retry);

          try {
            
            console.log('try /token/refresh...');

            console.log('refresh_token: ' + TokenService.getLocalRefreshToken());
            
            const rs = await axios.post("https://dev.rbodev.fr/api/token/refresh",{
              refresh_token: TokenService.getLocalRefreshToken()
            });

            console.log('rs: ' + JSON.stringify(rs));

            const { accessToken } = rs.refresh_token;
            
            console.log('refreshed token: ' + rs.refresh_token);

            store.dispatch('auth/refreshToken', accessToken);
            TokenService.updateLocalAccessToken(accessToken);

            return axiosInstance(originalConfig);

          } catch (error) {

            console.log('catch _error : ' + JSON.stringify(error));
            
            return Promise.reject(error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
  */

};

export default setupInterceptors;
