<template>
  <div>
    <!-- Social Info -->
    <div class="d-flex align-items-center justify-content-between">
        <span></span>
        <a v-if="f" :href="f" target="_blank"><i class="fab fa-facebook-square fa-2x text-rbo"></i></a>
        <a v-else href="#" style="opacity:0.3;"><i class="fab fa-facebook-square fa-2x text-rbo"></i></a>

        <a v-if="l" :href="l" target="_blank"><i class="fab fa-linkedin fa-2x text-rbo"></i></a>
        <a v-else href="#" style="opacity:0.3;"><i class="fab fa-linkedin fa-2x text-rbo"></i></a>

        <a v-if="i" :href="i" target="_blank"><i class="fab fa-instagram-square fa-2x text-rbo"></i></a>
        <a v-else href="#" style="opacity:0.3;"><i class="fab fa-instagram-square fa-2x text-rbo"></i></a>
        <span></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SocialNetwork',
  props: ['f', 'i', 'l'],
  data: function() {
    return {
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>