<template>
  <div>

    <Header title="Réunion" />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left"></i> Mon Espace Membre</a></li>
            <li class="breadcrumb-item"><a href="/groups">Mon groupe</a></li>
          </ol>
        </nav>
      </div>

      <div class="container">
        <div class="card">
          <div class="card-body">
            <!-- Infos réunion -->
            <div v-if="loggedIn" v-bind:class="loggedIn?'tab-pane show active':'tab-pane'" id="myGroup" role="tabpanel" aria-labelledby="myGroup-tab">
              <div class="container mx-0 px-0 mb-3">
                <div>
                  <div class="card bg-rbo rounded-0 rounded-top">
                    <div class="card-body py-3">
                      <h6 class="mb-0 text-white line-height-1">Informations</h6>
                    </div>
                  </div>
                  <div class="card rounded-0 rounded-bottom">
                    <ul class="list-group">
                      <li class="list-group-item">Réunion du {{reunion.date|formatDate}} à {{reunion.heureDebut}}</li>
                      <li v-if="reunion.ordreDuJour" class="list-group-item">Ordre du jour : {{reunion.ordreDuJour}}</li>
                      <li class="list-group-item">Lieu : {{reunion.lieu}}</li>
                      <li class="list-group-item">Lien Visio : <a :href="userData.groupe.urlJitsi" target="_blank">{{userData.groupe.urlJitsi}}</a></li>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Invités à la réunion -->
              <div>
                <div class="card bg-rbo rounded-0 rounded-top">
                  <div class="card-body p-1">
                     <div class="d-flex align-items-center justify-content-between">
                      <span></span>
                      <h6 class="pt-2 ps-5 text-white">Invités</h6>
                      <a href="#" class="py-1 pe-2" data-bs-toggle="modal" data-bs-target="#modalGuest"><i class="card-btn bi bi-plus-lg text-white"></i></a>
                    </div>
                  </div>
                </div>
                <div class="card rounded-0 rounded-bottom">
                  <ul class="ps-0 chat-user-list rounded-0" v-if="guests.length > 0">
                    <li v-for="(guest, index) in guests" :key="guest.id" class="rounded-0 px-3 pt-2 pb-2 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (guests.length-1) }">
                      <a class="d-flex" v-bind:href="'/invites/' + guest.id">
                        <div class="badge-avater-group">
                            <img v-if="guest.member.contact.photo" v-bind:src="urlUploads + guest.member.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="emetteur">
                            <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">

                            <img v-if="guest.contact.photo" v-bind:src="urlUploads + guest.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="beneficiaire">
                            <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                        </div>
                        <div class="chat-user-info" style="padding-left: 2rem;">
                          <h6 class="mt-2 mb-0">{{ guest.contact.firstName }} {{ guest.contact.lastName }} ({{ guest.contact.organisme.name }})</h6>
                          <div class="last-chat">
                            <p class="mb-0">
                              <Statut :statut="guest.guestStatut" />
                            </p>
                          </div>
                        </div>
                      </a>
                      <div class="dropstart chat-options-btn">
                        <a v-bind:href="'/invite/' + guest.id" class="text-rbo">
                          <i class="fas fa-chevron-right"></i>
                        </a>
                      </div>
                    </li>
                  </ul>  
                  <div v-else class="card bg-danger rounded-0 rounded mt-2">
                    <div class="card-body text-center py-3">
                      <h6 class="mb-0 text-white line-height-1">Aucun invité</h6>
                    </div>
                  </div>

                </div>
              </div>

              <!-- newGuest > modal -->
              <div class="add-new-contact-modal modal fade px-0" id="modalGuest" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalGuest" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body p-1">
                      <div class="d-flex align-items-center justify-content-between">
                        <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalGuest" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                        <GuestForm />    
                    </div>
                  </div>
                </div>
              </div>

          </div>
        </div>  
      </div>
    </div>

  </div>
<div class="pb-3"></div>
  <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import GuestForm from '@/components/InviteForm.vue'
import Statut from '@/components/InviteStatut.vue'
import UserService from '../services/user.service'

export default {
  name: 'Reunion',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      reunion: [],
      userData: [],
      guests: [],
      urlUploads: this.$urlUploads,
    }
  },
  components: {
    Header,
    Footer,
    GuestForm,
    Statut,
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    await UserService.getReunion(this.$route.params.id).then((response) => {
        this.reunion = response.data;
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    await UserService.getReunionEvents('?rId=' + this.$route.params.id + '&t=guest').then(
        response => {
          this.guests = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    
  },
}
</script>
