<template>
  <div>

    <Header title="Mon Espace Membre"/>

    <div class="page-content-wrapper">

      <div class="container">
        <div class="card mb-3 user-info-card">
          <div class="card-body d-flex align-items-center">
            <div class="user-profile me-3">
              <img v-if="avatar" v-bind:src="this.$urlUploads + '/' + avatar" class="avatar-img rounded-circle" alt="photo">
              <img v-else-if="photo" v-bind:src="this.$urlUploads + photo" class="avatar-img rounded-circle" alt="photo">
              <img v-else src="img/avatar.png" class="avatar-img rounded-circle" alt="photo">
            </div>
            <div class="user-info">
              <div class="d-flex">
                <h5 class="mb-1">{{userData.contact.fullName}}</h5>
              </div>
              <p class="d-flex mb-0"><strong v-if="userData.organisme.enseigne">{{userData.organisme.enseigne}} - &nbsp; </strong> <strong> {{userData.organisme.name}}</strong></p>
              <p class="d-flex mb-0">{{userData.contact.jobTitle}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- dashboard > listGroup -->
      <div v-if="isMember" class="container">
        <div class="card mb-3">
          <div class="card bg-rbo rounded-0 rounded-top">
            <div class="card-body py-3">
              <h6 class="mb-0 text-white line-height-1">Mon tableau de bord</h6>
            </div>
          </div>
          <ul class="list-group">
              <a href="/rems"><li class="list-group-item d-flex align-items-center justify-content-between">
                <div><i class="fas fa-utensils" style="padding-right:1rem;"></i>R.E.M</div>
                <span class="badge rounded-pill" v-bind:class="rem ? 'bg-success' : 'bg-warning'">{{rem ? rem : '0'}}</span>
              </li></a>
              <a href="/miresG"><li class="list-group-item d-flex align-items-center justify-content-between">
                <div><i class="fas fa-exchange-alt" style="padding-right:0.8rem;"></i>M.I.R.E émises</div>
                <span class="badge rounded-pill" v-bind:class="mireGiven ? 'bg-success' : 'bg-warning'">{{mireGiven ? mireGiven : '0' }}</span>
              </li></a>
              <a href="/miresR"><li class="list-group-item d-flex align-items-center justify-content-between">
                <div><i class="fas fa-exchange-alt" style="padding-right:0.6rem;"></i>M.I.R.E reçues</div>
                <span class="badge rounded-pill" v-bind:class="mireReceived ? 'bg-success' : 'bg-warning'">{{mireReceived ? mireReceived : '0' }}</span>
              </li></a>
              <a href="/merciCas"><li class="list-group-item d-flex align-items-center justify-content-between">
                <div><i class="fas fa-hand-holding-usd" style="padding-right:0.7rem;"></i>Merci CA</div>
                <span class="badge rounded-pill" v-bind:class="merciCaGivenNb ? 'bg-success' : 'bg-warning'">{{merciCaGivenNb ? merciCaGivenNb : '0' }}</span>
              </li></a>
              <a href="/merciCas"><li class="list-group-item d-flex align-items-center justify-content-between">
                <div><i class="fas fa-hand-holding-usd" style="padding-right:0.7rem;"></i>Merci CA</div>
                <span class="badge rounded-pill" v-bind:class="merciCaGivenTotal ? 'bg-success' : 'bg-warning'">{{merciCaGivenTotal|formatDecimal }} €</span>
              </li></a>
              <a href="/invites"><li class="list-group-item d-flex align-items-center justify-content-between">
                <div><i class="fas fa-handshake" style="padding-right:0.6rem;"></i>Mes invités</div>
                <span class="badge rounded-pill" v-bind:class="guest ? 'bg-success' : 'bg-warning'">{{guest ? guest : '0' }}</span>
              </li></a>
            </ul>
        </div>
      </div>

      <!-- myGroup -->
      <div class="container mb-3">
        <div v-if="isMember">
          <div v-if="!isManyMembership" class="card bg-rbo rounded-0 rounded-top">
            <div class="card-body text-center py-3">
              <h6 class="mb-0 text-white line-height-1">Mon groupe</h6>
            </div>
          </div>
          <div v-if="isManyMembership" class="card bg-rbo rounded-0 rounded-top">
            <div class="d-flex align-items-center justify-content-between py-1">
              <span></span>
              <h6 class="pt-2 ps-5 text-white">Mon groupe</h6>
              <a v-bind:href="'/groupChoice'" class="py-1 pe-2"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
            </div>
          </div>
          <div class="card rounded-0 rounded-bottom">
            <ul class="list-group">
              <li class="list-group-item">
                <a @click="goToGroupe(userData.groupe.id)" v-bind:href="'/groups/' + userData.groupe.id">            
                <strong>{{userData.groupe.name}}</strong></a>
              </li>
              <li v-if="userData.groupe.reunionJour" class="list-group-item">Réunion chaque {{userData.groupe.reunionJour}} à {{userData.groupe.reunionHeure}}</li>
              <li v-else class="list-group-item">Réunion : <span class="badge bg-warning text-dark">Informations non renseignées</span></li>

              <li v-if="userData.groupe.urlJitsi" class="list-group-item">Lien Visio : <a :href="userData.groupe.urlJitsi" target="_blank">{{userData.groupe.urlJitsi}}</a></li>
              <li v-else class="list-group-item">Lien Visio : <span class="badge bg-warning text-dark">Non renseigné</span></li>

              <li class="list-group-item rounded-bottom">Mon rôle : {{getLabelFonction(userData.member.fonction)}}</li>
              <li class="list-group-item"><SocialNetwork :f="group.facebook" :i="group.instagram" :l="group.linkedIn" /></li>
            </ul>
          </div>
        </div>
        <div v-if="!isMember" class="card bg-danger rounded-0 rounded mb-3">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Membre d'aucun groupe</h6>
          </div>
        </div>
      </div>

      <!-- company -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="d-flex align-items-center justify-content-between py-1">
            <span></span>
            <h6 class="pt-2 ps-5 text-white">Mon entreprise</h6>
            <a href="#" class="py-1 pe-2" data-bs-toggle="modal" data-bs-target="#modalCompany"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><strong>{{userData.organisme.name}}</strong></li>
            <li v-if="userData.organisme.enseigne" class="list-group-item text-justify">Enseigne : {{userData.organisme.enseigne}}</li>
            <li v-if="userData.organisme.activities" class="list-group-item text-justify">{{userData.organisme.activities}}</li>
            <li v-if="!userData.organisme.activities" class="list-group-item">Activités : <span class="badge bg-warning text-dark">Non renseigné</span></li>
            <li v-if="userData.organisme.siteWeb" class="list-group-item">Site web : <a :href="userData.organisme.siteWeb" target="_blank">{{userData.organisme.siteWeb}}</a></li>
            <li v-if="!userData.organisme.siteWeb" class="list-group-item">Site web : <span class="badge bg-warning text-dark">Non renseigné</span></li>
          </ul>
        </div>

        <!-- company > updated -->
        <div v-if="companyUpdated" class="alert d-flex align-items-center alert-success alert-dismissible fade show" role="alert">
          Les informations de votre entreprise ont bien été mises à jour...
          <button class="btn btn-close position-relative p-1 ms-auto" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

      </div>

      <!-- company > modal -->
      <div class="add-new-contact-modal modal fade px-0" id="modalCompany" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalCompany" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <h6 class="modal-title">Mon entreprise</h6>
                <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalCompany" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <FormulateForm
                name="formCompany"
                @submit="updateCompany"
              >
                <FormulateInput
                  type="text"
                  v-model=companySiteWeb
                  label="Site web"
                  placeholder="Activités de votre entreprise..."
                  validation="required"
                />
                <FormulateInput
                  type="textarea"
                  v-model=companyActivities
                  label="Activités"
                  validation="required|max:500,length"
                  :validation-messages="{
                      max: 'La description de vos activités ne devrait pas dépasser 500 caractères...'
                    }" 
                />
                <FormulateInput
                  type="submit"
                  label="Mettre à jour"
                  input-class="btn btn-rbo w-100 text-white"
                />
              </FormulateForm>

            </div>
          </div>
        </div>
      </div>

      <!-- contact > infos -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="d-flex align-items-center justify-content-between py-1">
            <span></span>
            <h6 class="pt-2 ps-5 text-white">Mes coordonnées</h6>
            <a href="#" class="py-1 pe-2" data-bs-toggle="modal" data-bs-target="#editContact"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item">Email : <a v-bind:href="'mailto:' + userData.contact.email"> {{userData.contact.email}}</a></li>
            <li class="list-group-item">
              Mobile : {{userData.contact.mobile}}
            </li>
            <li v-if="userData.contact.phone" class="list-group-item">Téléphone : {{userData.contact.phone}}</li>
          </ul>
        </div>
      </div>

      <!-- contact > modal -->
      <div class="add-new-contact-modal modal fade px-0" id="editContact" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="editContact" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <h6 class="modal-title" id="addnewcontactlabel">Mes coordonnées</h6>
                <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalContact" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="alert bg-secondary text-white py-2">
                <i class="fas fa-exclamation-triangle me-2"></i>
                Votre email ne peut pas être modifié ici...
              </div>
              <FormulateForm
                name="formContact"
                @submit="updateContact"
              >
                <FormulateInput
                  type="tel"
                  v-model=contactMobile
                  label="Mobile"
                  placeholder="Votre mobile pro..."
                  validation="required"
                />
                <FormulateInput
                  type="tel"
                  v-model=contactPhone
                  label="Téléphone"
                  placeholder="Votre numéro de téléphone pro..."
                />
                <FormulateInput
                  type="submit"
                  label="Mettre à jour"
                  input-class="btn btn-rbo w-100 text-white"
                />
              </FormulateForm>

            </div>
          </div>
        </div>
      </div>

      <div class="pb-1"></div>

      <Footer />

    </div>
  </div>
</template>

<script>
import router from '../router'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import SocialNetwork from '@/components/SocialNetwork.vue'
import UserService from '../services/user.service';
import RboService from '../services/rbo.service';

export default {
  name: 'Profile',
  components: {
    Header,
    Footer,
    SocialNetwork
  },
  data: function() {
    return {
      error: null,
      userData: [],
      group: [],
      photo: null,
      avatar: null,
      isMember: true,
      isManyMembership: false,
      rem: 0,
      mireGiven: 0,
      mireReceived: 0,
      merciCaGivenNb: 0,
      merciCaGivenTotal: 0,
      guest: 0,
      contactUpdated: false,
      contactMobile: null,
      contactPhone: null,
      companyUpdated: false,
      companySiteWeb: null,
      companyActivities: null,
    }
  },
  filters: {
    formatDecimal: function (value) {
      if (!value) return '0'
      value = (value).toFixed(2);
      return value;
    }
  }, 
  async created() {
    if (!this.currentUser) {
      router.push('/login');
    }

    // userData
    await UserService.getUserMe().then(
      response => {
        localStorage.setItem('userData', response.data);
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );

    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.photo = this.userData.contact.photo;
    this.avatar = this.userData.user.avatar;
    this.contactMobile = this.userData.contact.mobile;
    this.contactPhone = this.userData.contact.phone;
    this.companySiteWeb = this.userData.organisme.siteWeb;
    this.companyActivities = this.userData.organisme.activities;
    if(this.userData.member.length == 0) { this.isMember = false; }
    else {
      this.rem = this.userData.rem.nb;
      this.mireGiven = this.userData.mire.given;
      this.mireReceived = this.userData.mire.received;
      this.merciCaGivenNb = this.userData.merciCa.given.nb;
      this.merciCaGivenTotal = this.userData.merciCa.given.total;
      this.guest = UserService.getReunionEvents('?mId=' + this.userData.member.id + '&t=guest').then(
        response => {
          this.guest = response.data['hydra:totalItems'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

      // members (autres groupes)
      if(this.userData.members.length > 0) {
        this.isManyMembership = true;
      }
    }

    // groupe
    await UserService.getGroup(this.userData.groupe.id).then((response) => {
        localStorage.setItem('group', JSON.stringify(response.data));
        this.group = response.data;  
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
  },
  mounted() {
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
    })
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {

    getLabelFonction: function (role) {
      return RboService.getLabelFonction(role);
    },

    goToGroupe(id) {
      this.$router.push({name:'groupe', params:{id:id}})
    },

    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          let lsUserData = JSON.parse(localStorage.getItem('userData'));
          this.$store.dispatch('auth/setIsMember', lsUserData.member.length > 0 ? true : false);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },

    async updateCompany() {
      let data = {
        'siteWeb': this.companySiteWeb,
        'detail': this.companyActivities
        };
      let dataJson = JSON.stringify(data);
      await UserService.patchOrganisme(this.userData.organisme.id, dataJson).then(
        response => {
          this.companySiteWeb = response.data.siteWeb;
          this.companyActivities = response.data.detail;
        },
        error => {
          this.error = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      document.getElementById('closeModalCompany').click();
      this.reload();
    },

    async updateContact() {
      let data = {
        'mobilePhone': this.contactMobile,
        'officePhone': this.contactPhone
        };
      let dataJson = JSON.stringify(data);
      await UserService.patchContact(this.userData.contact.id, dataJson).then(
        response => {
          console.log('company updated! ' + JSON.stringify(response.data));
          //this.contactMobile = response.data.mobile;
          //this.companyActivities = response.data.detail;
        },
        error => {
          this.error = (error.response && error.response.data) || error.message || error.toString();
          console.log('patchContact error:' + JSON.stringify(error));
        }
      );
      document.getElementById('closeModalContact').click();
      this.reload();
    },

  },
};
</script>

<style>
.list-group-item {
  text-align: left;
}
.text-justify {
  text-align: justify;
}
.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}
.rounded-circle {
  border-radius: 50% !important;
}
.nav-item .btn {
  font-weight: 600;
}
.card-btn {
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1000;
  background-color: #31547e;
  text-align: center;
  line-height: 2.5rem;
  border-radius: 50%;
  color: #ffffff;
}
.input-group-text {
  color: white;
  background-color: #31547e;
  border-color: #31547e;
}
</style>