<template>
  <div class="rems">

    <Header :title=title />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left ml-2"></i> Mon Espace Membre</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">

        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">{{rems.length == 0 ? '' : 'Mes ' + (rems.length > 30 ? '30' : rems.length)  + ' dernières'}} R.E.M</h6>
          </div>
        </div>

        <ul v-if="rems.length > 0" class="ps-0 chat-user-list rounded-0">
          <li v-for="(rem, index) in rems" :key="rem.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (rems.length-1) }">
            <a class="d-flex" v-bind:href="'/rems/' + rem.id">
              <div class="badge-avater-group">
                  <!--<a class="badge-avater badge-avater-lg" href="#"><img class="img-circle" src="/img/bg-img/user1.png" alt=""></a>-->
                  <img v-if="rem.member1.contact.photo" v-bind:src="urlUploads + rem.member1.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                  <!--<a class="badge-avater badge-avater-lg" href="#"><img class="img-circle" src="/img/bg-img/user2.png" alt=""></a>-->
                  <img v-if="rem.member2.contact.photo" v-bind:src="urlUploads + rem.member2.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                  <!--<span class="ms-1 badge bg-success">1</span>-->
                  <img v-if="rem.photoThumb" v-bind:src="urlUploads + rem.photoThumb" class="badge-avater badge-avater-sm mt-4">
                  <img v-else v-bind:src="urlWeb + 'img/no-image.png'" class="badge-avater badge-avater-sm mt-4">
              </div>
              
              <div class="chat-user-info" style="padding-left: 2rem;">
                <h6 v-if="lsUserData.member.id != rem.member1.id" class="mt-2 mb-0">R.E.M du {{rem.date|formatDate}}
                  <br> avec {{rem.member1.contact.firstName}} {{rem.member1.contact.lastName}}</h6>
                <h6 v-else class="mt-2 mb-0">R.E.M du {{rem.date|formatDate}}
                  <br> avec {{rem.member2.contact.firstName}} {{rem.member2.contact.lastName}}</h6>

                <div class="last-chat">
                  <p class="mb-0">
                    
                  </p>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/rems/' + rem.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-warning rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucune R.E.M</h6>
          </div>
        </div>
        <div class="add-new-contact-wrap"><a class="shadow" href="#" data-bs-toggle="modal" data-bs-target="#newRem"><i class="bi bi-plus-lg"></i></a></div>
          <div class="add-new-contact-modal modal fade px-0" id="newRem" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalRem" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <Form :type="formType" />
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
    <div class="pb-3"></div>
    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/RemForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Rems',
  data: function() {
    return {
      formType: '',
      rems: [],
      title: "Mes R.E.M",
      urlWeb: this.$urlWeb,
      urlUploads: this.$urlUploads,
      loggedIn: this.$store.state.auth.status.loggedIn,
      lsUserData: []
    }
  },
  components: {
    Header,
    Footer,
    Form
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  },
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));
    UserService.getRems('?mId=' + this.lsUserData.member.id).then(
        response => {
          this.rems = response.data['hydra:member'];
          localStorage.setItem('rems', JSON.stringify(this.rems));
        },
        error => {
          if(localStorage.getItem('rems')) {
            this.rems = JSON.parse(localStorage.getItem('rems'));
          }
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      )
  },
  /*
  mounted() {
    if(this.rems.length == 0 && localStorage.getItem('rems')) {
      this.rems = JSON.parse(localStorage.getItem('rems'));
    }
  },
  */
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.nav-item .btn {
  font-weight: 600;
}
.text-left {
  text-align: left;
}
.badge-avater.badge-avater-selfie {
    width: 1rem;
    height: 1rem;
    font-size: 8px;
    line-height: 1rem;
    margin-top: 2rem;
    margin-left: 0.2rem;
}
</style>