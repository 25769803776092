<template>
  <div>

    <Header :title=title />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left ml-2"></i> Mon Espace Membre</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">

        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">
              {{mires.length == 0 ? '' : 'Mes ' + (mires.length > 30 ? '30' : mires.length) + ' dernières'}} M.I.R.E {{type == 'G' ? 'émises' : 'reçues'}}
            </h6>
          </div>
        </div>

        <ul v-if="mires.length > 0" class="ps-0 chat-user-list rounded-0">
          <li v-for="(mire, index) in mires" :key="mire.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (mires.length-1) }">
            <a class="d-flex" v-bind:href="'/mires/' + mire.id">
              <div class="badge-avater-group">
                  <img v-if="mire.emetteur.contact.photo" v-bind:src="urlUploads + mire.emetteur.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="emetteur">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">

                  <img v-if="mire.beneficiaire && mire.beneficiaire.contact.photo" v-bind:src="urlUploads + mire.beneficiaire.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="beneficiaire">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
              </div>
              
              <div class="chat-user-info" style="padding-left: 2rem;">
                <h6 class="mt-2 mb-0">M.I.R.E du {{mire.date|formatDate}}</h6>

                <div class="last-chat">
                  <p v-if="mire.merciCa" class="mb-0">
                    <a v-bind:href="/merciCas/ + mire.merciCa.id"><span class="badge bg-success">Transformée en Merci CA</span></a>
                  </p>
                  <p class="mb-0">
                    {{ mire.contact.firstName }} {{ mire.contact.lastName }} ({{mire.contact.organisme.name}})
                  </p>
                  <p class="mb-0">
                    {{ mire.sujet}}
                  </p>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/mires/' + mire.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-warning rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucune M.I.R.E</h6>
          </div>
        </div>

        <div v-if="type == 'G'" class="add-new-contact-wrap"><a class="shadow" href="#" data-bs-toggle="modal" data-bs-target="#newMire"><i class="bi bi-plus-lg"></i></a></div>
          <div class="add-new-contact-modal modal fade px-0" id="newMire" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalMire" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <Form :type="formType" />
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>

    <div class="pb-3"></div>
    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/MireForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Mires',
  data: function() {
    return {
      mires: [],
      type: null, 
      title: "Mes M.I.R.E",
      loggedIn: this.$store.state.auth.status.loggedIn,
      urlUploads: this.$urlUploads,
      formType: '',
    }
  },
  components: {
    Header,
    Footer,
    Form
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    let lsUserData = JSON.parse(localStorage.getItem('userData'));
    /*
    UserService.getMires('?mId=' + lsUserData.member.id).then(
        response => {
          this.mires = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    */
    if(this.$route.path == '/miresG') {
      UserService.getMires('?mId=' + lsUserData.member.id + '&t=g').then(
        response => {
          this.mires = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.type = 'G';
    }
    if(this.$route.path == '/miresR') {
      UserService.getMires('?mId=' + lsUserData.member.id + '&t=r').then(
        response => {
          this.mires = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.type = 'R';
    }
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }

}
</script>

<style>
.nav-item .btn {
  font-weight: 600;
}
.text-left {
  text-align: left;
}
</style>