<template>
  <div>

    <Header :title=title />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left ml-2"></i> Mon Espace Membre</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Mes 10 derniers invités</h6>
          </div>
        </div>

        
        <ul v-if="guests.length > 0 " class="ps-0 chat-user-list rounded-0">
          <li v-for="(guest, index) in guests" :key="guest.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (guests.length-1) }">
            <a class="d-flex" v-bind:href="'/invites/' + guest.id">
              <div class="badge-avater-group">
                  <img v-if="guest.member.contact.photo" v-bind:src="urlUploads + guest.member.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="emetteur">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">

                  <img v-if="guest.contact.photo" v-bind:src="urlUploads + guest.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="beneficiaire">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
              </div>
              
              <div class="chat-user-info" style="padding-left: 2rem;">
                <h6 class="mt-2 mb-0">{{ guest.contact.firstName }} {{ guest.contact.lastName }} ({{ guest.contact.organisme.name }})</h6>

                <div class="last-chat">
                  <p class="mb-0">
                    Invité à la réunion du {{ guest.reunion.date|formatDate }}
                    <Statut :statut="guest.guestStatut" />
                  </p>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/invite/' + guest.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-warning rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucun invité</h6>
          </div>
        </div>
        <div class="add-new-contact-wrap"><a class="shadow" href="#" data-bs-toggle="modal" data-bs-target="#newGuest"><i class="bi bi-plus-lg"></i></a></div>
          <div class="add-new-contact-modal modal fade px-0" id="newGuest" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalGuest" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <Form :type="formType" />
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/InviteForm.vue'
import Statut from '@/components/InviteStatut.vue'
import UserService from '../services/user.service';

export default {
  name: 'Invites',
  data: function() {
    return {
      lsUserData: [],
      guests: [],
      title: "Mes invités",
      loggedIn: this.$store.state.auth.status.loggedIn,
      urlUploads: this.$urlUploads,
      formType: '',
    }
  },
  components: {
    Header,
    Footer,
    Form,
    Statut
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));
    UserService.getReunionEvents('?mId=' + this.lsUserData.member.id + '&t=guest').then(
        response => {
          this.guests = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
  },
   watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>