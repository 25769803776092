<template>
  <div>

    <!-- Header Area -->
    <div class="header-area" id="headerArea">
      <div class="container">
        <div class="header-content header-style-five position-relative d-flex align-items-center justify-content-between">
          <!-- Logo -->
          <div v-if="!urlBack" class="logo-wrapper"><a href="/home"><img src="/img/rbo/logo-sm.png" alt=""></a></div>
          <!-- Back Button -->
          <div v-if="urlBack" class="back-button">
            <a :href="urlBack"><i class="fas fa-chevron"></i></a>
          </div>
          <!-- Page Title -->
          <div class="page-heading">
            <h6 class="mb-0">{{title}}</h6>
          </div>
          <!-- Navbar Toggler -->
          <div class="navbar--toggler" id="affanNavbarToggler" data-bs-toggle="offcanvas" data-bs-target="#affanOffcanvas" aria-controls="affanOffcanvas"><span class="d-block"></span><span class="d-block"></span><span class="d-block"></span></div>
        </div>
      </div>
    </div>

    <!-- Offcanvas -->
    <div class="offcanvas offcanvas-start" id="affanOffcanvas" data-bs-scroll="true" tabindex="-1" aria-labelledby="affanOffcanvsLabel">
      <button class="btn-close btn-close-white text-reset" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      <div class="offcanvas-body p-0">
        <div class="sidenav-wrapper">
          <div class="sidenav-profile bg-rbo">
            <div class="sidenav-style1"></div>
            <!-- User -->
            <div v-if="loggedIn">
                <img v-if="avatar" v-bind:src="this.$urlUploads + '/' + avatar" class="avatar-img rounded-circle" alt="photo">
                <img v-else-if="photo" v-bind:src="this.$urlUploads + photo" class="avatar-img rounded-circle" alt="photo">
                <img v-else src="/img/avatar.png" class="avatar-img rounded-circle" alt="photo">
                <div class="user-info">
                    <h6 v-if="loggedIn && userData.contact && userData.contact.fullName" class="user-name mb-0">{{userData.contact.fullName}}</h6>
                    <span v-if="loggedIn && userData.organisme && userData.organisme.name" ><span v-if="userData.organisme.enseigne">{{userData.organisme.enseigne}} - </span>{{userData.organisme.name}}</span>
                </div>
            </div>
            <!-- Logo -->
            <div v-if="!loggedIn" class="container">
              <div class="pb-3"></div>
              <div class="card pt-3">
                <div class="card-body">
                  <div>
                    <img src="/img/rbo/logo.png" class="img-fluid" alt="logo">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Sidenav Nav -->
          <ul class="sidenav-nav ps-0">
            <li><a href="/home"><i class="bi bi-house-door"></i>Accueil</a></li>
            <li v-if="!loggedIn"><a href="/groups"><i class="bi bi-person-bounding-box"></i>Trouver un groupe</a></li>
            <li v-if="loggedIn"><a href="/profile"><i class="bi bi-person-fill"></i>Mon Espace Membre</a></li>
            <li v-if="loggedIn"><a href="/groups"><i class="bi bi-person-bounding-box"></i>Mon groupe</a></li>
            <li v-if="loggedIn"><a href="/members"><i class="fa fa-street-view"></i>Annuaire</a></li>
            <li v-if="loggedIn && isMember">
              <a href="/rems">
                <i class="fas fa-utensils"></i>Mes REM
                <span class="badge rounded-pill float-right ms-2" v-bind:class="userData.rem.nb ? 'bg-success' : 'bg-warning'">{{userData.rem.nb}}</span>
            </a></li>
            <li v-if="loggedIn && isMember">
              <a href="/miresG">
                <i class="fas fa-exchange-alt"></i>Mes MIRE émises
                <span class="badge rounded-pill ms-2" v-bind:class="userData.mire.given ? 'bg-success' : 'bg-warning'">{{userData.mire.given}}</span>
              </a>
            </li>
            <li v-if="loggedIn && isMember">
              <a href="/miresR">
                <i class="fas fa-exchange-alt"></i>Mes MIRE reçues
                <span class="badge rounded-pill ms-2" v-bind:class="userData.mire.received ? 'bg-success' : 'bg-warning'">{{userData.mire.received}}</span>
              </a>
            </li>
            <li v-if="loggedIn && isMember">
              <a href="/merciCas"><i class="fas fa-hand-holding-usd"></i>
              Mes MERCI CA<span class="badge rounded-pill ms-2" v-bind:class="userData.merciCa.given.nb ? 'bg-success' : 'bg-warning'">{{userData.merciCa.given.nb}} > {{userData.merciCa.given.total|formatDecimal}} €</span></a>
            </li>
            <li v-if="loggedIn && isMember">
              <a href="/invites"><i class="fas fa-handshake"></i>
              Mes invités<span class="badge rounded-pill ms-2" v-bind:class="guests ? 'bg-success' : 'bg-warning'">{{guests}}</span></a>
            </li>
            <!--
            <li><a href="pages.html"><i class="bi bi-collection"></i>Pages<span class="badge bg-success rounded-pill ms-2">100+</span></a></li>
            <li><a href="#"><i class="bi bi-cart-check"></i>Shop</a>
              <ul>
                <li><a href="page-shop-grid.html">Shop Grid</a></li>
                <li><a href="page-shop-list.html">Shop List</a></li>
                <li><a href="page-shop-details.html">Shop Details</a></li>
                <li><a href="page-cart.html">Cart</a></li>
                <li><a href="page-checkout.html">Checkout</a></li>
              </ul>
            </li>
            <li><a href="settings.html"><i class="bi bi-gear"></i>Settings</a></li>
            <li>
              <div class="night-mode-nav"><i class="bi bi-moon"></i>Night Mode
                <div class="form-check form-switch">
                  <input class="form-check-input form-check-success" id="darkSwitch" type="checkbox">
                </div>
              </div>
            </li>
            -->
            <li v-if="!loggedIn"><a href="/login"><i class="bi bi-box-arrow-in-right"></i>Connexion</a></li>
            <li v-if="loggedIn"><a href="/home" @click.prevent="logOut"><i class="bi bi-box-arrow-right"></i>Déconnexion</a></li>
            <li v-if="loggedIn"><a href="/home" @click.prevent="deleteCaches"><i class="bi bi-box-arrow-right"></i>Supprimer le cache</a></li>
          </ul>
          
          <!-- Social Info -->
          <div class="social-info-wrap">
            <!--
            <a href="https://facebook.com" target="_blank"><i class="bi bi-facebook"></i></a>
            <a href="https://linkedin.com" target="_blank"><i class="bi bi-linkedin"></i></a>
            <a href="https://twitter.com" target="_blank"><i class="bi bi-twitter"></i></a>
            -->
          </div>
          
          <!-- Copyright Info -->
          <div class="copyright-info">
            <p>
                &copy; 2021 -
                Application développée pour les membres de
                <a href="https://www.rbodeveloppement.com" target="_blank">RBO Développement</a>
                par<a href="https://www.betterb.fr" target="_blank">BetterB</a>
                - Version {{version}}
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserService from '../services/user.service';
const userData = [];

export default {
  name: 'Header',
  props: ['title', 'urlBack'],
  data: function() {
    return {
        version: this.$version,
        loggedIn: this.$store.state.auth.status.loggedIn,
        photo: null,
        avatar: null,
        userData: userData,
        isMember: true,
        guests: 0
    }
  },
  filters: {
    formatDecimal: function (value) {
      if (!value) return '0'
      value = (value).toFixed(2);
      return value;
    }
  }, 
  created() {
    if (localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      this.photo = this.userData.contact.photo;
      this.avatar = this.userData.user.avatar;
      if(this.userData.member.length == 0) { this.isMember = false; }
      this.guests = UserService.getReunionEvents('?mId=' + this.userData.member.id + '&t=guest').then(
        response => {
          this.guests = response.data['hydra:totalItems'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/home');
    },
    deleteCaches() {
      const staticCacheName = 'cache-v' + this.$version;
      const dynamicCacheName = 'runtimeCache-v' + this.$version;
      caches.delete(staticCacheName).then(function() {});
      caches.delete(dynamicCacheName).then(function() {});
      this.$router.go();
    }
  }
}
</script>

<style>
.header-title {
    color: #153760;
    padding-top: 0.4rem;
    font-weight: bold;
}
h6 {
    font-size: 1.2rem;
    font-weight: bolder;
}
</style>