<template>
  <div>
        <div class="card mb-3">
          <div class="card-body">

            <!-- mire form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center text-rbo">{{ editing ? 'Modifier' : 'Enregistrer' }} une M.I.R.E</h6>

              <FormulateForm @submit="validationForm">
                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date"
                    format="dd/MM/yyyy"
                    :disabled-dates="tomorrow.disabledDates"
                    v-model="mire.date"
                />

                <div class="text-left mb-1"><label>De</label></div>
                <Multiselect 
                  v-model="mire.emetteur" 
                  :options="formSelectMember1"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectMember1.label"
                  label="label"
                  track-by="value"
                  placeholder="Sélectionner le nom du membre"
                  :internal-search="false"
                  @search-change="searchChangeMember1"
                ></Multiselect>

                <!--
                <FormulateInput
                    :options=formSelectMember1
                    type="autocomplete"
                    name="member1"
                    validation="required"
                    :validation-messages="{
                      required: 'Veuillez indiquer le nom du membre...'
                    }" 
                    label="De"
                    :value="userData.contact.fullName + ' (' + userData.groupe.name + ')'"
                    v-model="mire.emetteur"
                />
                -->

                <div class="text-left mb-2 mt-3">
                  <label class="text-left">Pour</label>
                </div>
                <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioGuest" v-model="radioBeneficiaire" value="isGuest">
                    <label class="form-check-label" for="infoRadioGuest">Invité</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input  v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioInterne" v-model="radioBeneficiaire" value="isInterne">
                    <label class="form-check-label" for="infoRadioInterne">Interne</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioConfidential" v-model="radioBeneficiaire" value="isConfidential">
                    <label class="form-check-label" for="infoRadioConfidential">Confidentiel</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input  v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioInterGroup" v-model="radioBeneficiaire" value="isInterGroup">
                    <label class="form-check-label" for="infoRadioInterGroup">Inter groupe</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioOldMember" v-model="radioBeneficiaire" value="isOldMember">
                    <label class="form-check-label" for="infoRadioOldMember">Ancien membre</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = true" class="form-check-input form-check-info" type="radio" id="infoRadioMember" v-model="radioBeneficiaire" value="isMember">
                    <label class="form-check-label" for="infoRadioMember">Un membre du groupe</label>
                  </div>

                <Multiselect 
                  v-if="isSelectMemberShow"
                  v-model="mire.beneficiaire" 
                  :options="formSelectMembers"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectMembers.label"
                  label="label"
                  track-by="value"
                  placeholder="Sélectionner le nom du membre"
                  :internal-search="false"
                  @search-change="searchChangeMember2"
                ></Multiselect>

                <div v-if="isSelectMemberShow" class="mb-3"></div>

                <!--
                <FormulateInput
                    :options=formSelectMembers
                    type="autocomplete"
                    name="member2"
                    placeholder="Indiquer le nom du membre"
                    v-if="isSelectMemberShow"
                    v-model="mire.beneficiaire"
                />
                -->

                <FormulateInput
                  type="text"
                  name="sample"
                  label="Nom du contact"
                  placeholder="Saisisser le nom du contact"
                  validation="required"
                  :validation-messages="{
                      required: 'Le nom du contact est obligatoire'
                    }" 
                  v-model="mire.contactLastName"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Prénom du contact"
                  placeholder="Saisisser le prénom du contact"
                  validation="required"
                  :validation-messages="{
                      required: 'Le prénom du contact est obligatoire'
                    }"
                  v-model="mire.contactFirstName"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Métier du contact"
                  placeholder="Saisisser le métier du contact"
                  v-model="mire.contactJobTitle"
                />
                <FormulateInput
                  type="text"
                  name="sample"
                  label="Société du contact"
                  placeholder="Saisisser le nom de la société contact"
                  v-model="mire.contactCompany"
                />
                <FormulateInput
                  type="tel"
                  name="phone"
                  label="Tél. mobile"
                  placeholder="Saisisser le téléphone du contact"
                  v-model="mire.contactTel"
                />
                <FormulateInput
                  type="email"
                  name="email"
                  label="Courriel"
                  placeholder="Saisisser l'email du contact"
                  validation="required"
                  :validation-messages="{
                      required: 'L\'email du contact est obligatoire'
                    }"
                  v-model="mire.contactEmail"
                />
                <FormulateInput
                  type="textarea"
                  label="Sujet"
                  validation="required"
                  :validation-messages="{
                      required: 'Le sujet est obligatoire'
                    }"
                  v-model="mire.sujet"
                />

                <div v-if="isSelectMemberShow" class="form-check">
                  <input v-model="mire.isEmailSend" class="form-check-input" id="checkboxEmail" type="checkbox" value="">
                  <label class="form-check-label" for="checkboxEmail">Envoyer une copie au bénéficiaire</label>
                </div>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonMire"
                  input-class="btn btn-rbo w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
import router from '../router'
import UserService from '../services/user.service';
import Multiselect from 'vue-multiselect'
//import axios from 'axios';
//import authHeader from '../services/auth-header';

var temp = Object.freeze({
      date: '',
      emetteur: '',
      beneficiaire: '',
      isConfidential: false,
      isGuest: false,
      isOldMember: false,
      isInterGroupe: false,
      isInterne: false,
      contactLastName: '',
      contactFirstName: '',
      contactJobTitle: '',
      contactCompany: '',
      contactTel: '',
      contactEmail: '',
      sujet: '',
      isEmailSend: false,
    });

export default {
  name: 'Form',
  components: {
    Multiselect
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      formSelectMember1: [],
      formSelectMembers: [],
      radioBeneficiaire: null,
      isSelectMemberShow: false,
      error: null,
      mire: Object.assign({}, temp),
      editing: false,
      urlUploads: this.$urlUploads,
      tomorrow: null,
      member2Id: null,
      isBureau: false,
      isDDD: false,
      isRBO: false,
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
        await UserService.getMembersOtherForm().then(
        response => {
          localStorage.setItem('membersForm', JSON.stringify(response.data));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    
    this.userGroupMembers = JSON.parse(localStorage.getItem('membersForm'));

    const lsMembersForm = JSON.parse(localStorage.getItem('membersForm'));
    if( lsMembersForm ) { this.formSelectMembers = lsMembersForm['hydra:member']; }    

    if(localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'));
        // isBureau
        if( ['ROLE_BUREAU'].includes(this.userData.user.role) ) {
          this.isBureau = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isDDD
        else if( ['ROLE_DDD', 'ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isDDD = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isRBO or more
        else if( ['ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isRBO = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isMember
        else {
          this.formSelectMember1 =[{ value: this.userData.member.id, label: this.userData.contact.fullName }];
        }
    }

    this.tomorrow = {
      disabledDates: {
        from: new Date(),
    },
    }
  },
  watch: {
    type(val) {
      if (val == 'add') {
        this.mire = Object.assign({}, temp);
      }
    }
  },

  mounted() {
    if ("id" in this.$route.params) {
      this.getMireById();
      this.editing = true;
    } else {
      this.mire.date = new Date();
      this.mire.emetteur = this.userData.member ? {value : this.userData.member.id, label: this.userData.contact.fullName + ' (' + this.userData.groupe.name + ')'} : '';

    }
  },
  methods: {
    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },
    searchChangeMember1(search) {
      if(this.isBureau || this.isDDD || this.isRBO ) {
        this.formSelectMember1 = search
          ? (this.formSelectMember1.filter(option => this.normalizedContains(search, option.label)))
          : this.userGroupMembers['hydra:member'];
      } else {
         this.formSelectMember1 = search
        ? (this.formSelectMember1.filter(option => this.normalizedContains(search, option.label)))
        : [{ value: this.userData.member.id, label: this.userData.contact.fullName }];
      }
    },
    searchChangeMember2(search) {
      this.formSelectMembers = search
        ? (this.formSelectMembers.filter(option => this.normalizedContains(search, option.label)))
        : this.userGroupMembers['hydra:member'];
    },
    getMireById() {
      UserService.getMire(this.$route.params.id).then( 
          response => {
                    this.mire = response.data;

                    this.mire.emetteur.label = this.mire.emetteur.contact.firstName + ' ' + this.mire.emetteur.contact.lastName + ' (' + this.mire.emetteur.groupe.name + ')';
                    this.mire.emetteur.value = this.mire.emetteur.id;
                    //this.mire.emetteur = this.userData.member.id;

                    if (this.mire.isConfidential == true){this.radioBeneficiaire = 'isConfidential'}
                    if (this.mire.isGuest == true){this.radioBeneficiaire = 'isGuest'}
                    if (this.mire.isOldMember == true){this.radioBeneficiaire = 'isOldMember'}
                    if (this.mire.isInterGroupe == true){this.radioBeneficiaire = 'isInterGroup'}
                    if (this.mire.isInterne == true){this.radioBeneficiaire = 'isInterne'}
                    if (this.mire.beneficiaire){
                      this.radioBeneficiaire = 'isMember'

                      //this.member2Id = this.mire.beneficiaire.id;
                      //this.mire.beneficiaire = this.mire.beneficiaire.contact.firstName + ' ' + this.mire.beneficiaire.contact.lastName + ' (' + this.mire.beneficiaire.groupe.name + ')';

                      this.mire.beneficiaire.label = this.mire.beneficiaire.contact.firstName + ' ' + this.mire.beneficiaire.contact.lastName + ' (' + this.mire.beneficiaire.groupe.name + ')';
                      this.mire.beneficiaire.value = this.mire.beneficiaire.id; 
                    }

                    this.mire.contactLastName = this.mire.contact.lastName;
                    this.mire.contactFirstName = this.mire.contact.firstName;
                    this.mire.contactJobTitle = this.mire.contact.jobTitle;
                    this.mire.contactCompany = this.mire.contact.organisme.name;
                    this.mire.contactTel = this.mire.contact.mobilePhone;
                    this.mire.contactEmail = this.mire.contact.email;
                },
          error => {this.content =(error.response && error.response.data) || error.message || error.toString();}
        );
    },
    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          let lsUserData = JSON.parse(localStorage.getItem('userData'));
          this.$store.dispatch('auth/setIsMember', lsUserData.member.length > 0 ? true : false);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async uploadFile () {
     
    },
    async validationForm() {
        if("id" in this.$route.params) {
            //var valueBeneficiaire = document.getElementsByName('member2');
            const mire = {
                date: this.mire.date,
                emetteur: "/api/members/" + this.mire.emetteur.value,
                beneficiaire: this.radioBeneficiaire == 'isMember' ? ("/api/members/" + this.mire.beneficiaire.value) : null,
                
                isConfidential: this.radioBeneficiaire == 'isConfidential' ? true : false,
                isGuest: this.radioBeneficiaire == 'isGuest' ? true : false,
                isOldMember: this.radioBeneficiaire == 'isOldMember' ? true : false,
                isInterGroupe: this.radioBeneficiaire == 'isInterGroup' ? true : false,
                isInterne: this.radioBeneficiaire == 'isInterne' ? true : false,
                
                contactLastName: this.mire.contactLastName,
                contactFirstName: this.mire.contactFirstName,
                contactJobTitle: this.mire.contactJobTitle,
                contactCompany: this.mire.contactCompany,
                contactTel: this.mire.contactTel,
                contactEmail: this.mire.contactEmail,
                sujet: this.mire.sujet,
                isEmailSend: this.mire.isEmailSend,
                updatedBy: "/api/users/" + this.userData.user.id,
            };
             UserService.patchMire(this.$route.params.id, mire).then(
                () => {
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
            );
            document.getElementById('buttonMire').click();
            document.getElementById('closeModalMire').click();
            router.push('/miresG');
            this.reload();
        } else {
          //var valueMember2 = document.getElementsByName('member2');
          //var valueMember1 = document.getElementsByName('member1');
          const mire = {
                site: "/api/sites/" + this.userData.user.siteId,
                groupe: "/api/groupes/" + this.userData.groupe.id,
                date: this.mire.date,
                emetteur: "/api/members/" + this.mire.emetteur.value,
                beneficiaire: this.radioBeneficiaire == 'isMember' ? ("/api/members/" + this.mire.beneficiaire.value) : null,
                isConfidential: this.radioBeneficiaire == 'isConfidential' ? true : false,
                isGuest: this.radioBeneficiaire == 'isGuest' ? true : false,
                isOldMember: this.radioBeneficiaire == 'isOldMember' ? true : false,
                isInterGroupe: this.radioBeneficiaire == 'isInterGroup' ? true : false,
                isInterne: this.radioBeneficiaire == 'isInterne' ? true : false,
                
                contactLastName: this.mire.contactLastName,
                contactFirstName: this.mire.contactFirstName ? this.mire.contactFirstName : null,
                contactJobTitle: this.mire.contactJobTitle ? this.mire.contactJobTitle : null,
                contactCompany: this.mire.contactCompany ? this.mire.contactCompany : null,
                contactTel: this.mire.contactTel ? this.mire.contactTel : null,
                contactEmail: this.mire.contactEmail,
                sujet: this.mire.sujet,
                isEmailSend: this.mire.isEmailSend,
                createdBy: "/api/users/" + this.userData.user.id,
            };
            await UserService.postMire(mire).then(
                res => {
                  let mireId = res.data['@id'];
                  this.mireNewId = mireId.slice(10);
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
                
            );
            router.push('/mires/' + this.mireNewId);
            this.reload();
        }
    },  
  }
}
</script>

