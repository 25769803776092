<template>
  <div>

    <Header title="R.E.M" />

    <div class="page-content-wrapper">

      <!-- edit btn -->
      <!--
      <div class="add-new-contact-wrap">
        <a class="shadow" v-bind:href="{path: `/rems/formulaire/${this.id}`, params: { id: this.id }, query: { type: 'edit' }}">
        <i class="fas fa-pencil-alt"></i></a>
      </div>
      -->

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left"></i> Mon Espace Membre</a></li>
            <li class="breadcrumb-item"><a href="/rems">Liste des R.E.M</a></li>
          </ol>
        </nav>
      </div>
      
      <div class="container">
        <div class="card mb-3">
          <div class="card bg-rbo rounded-0 rounded-top">
            <div class="card-body py-3 pe-1">
              <div class="badge-avater-group">
                <!-- member1 -->
                <img v-if="rem.member1.contact.photo" v-bind:src="urlUploads + rem.member1.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                <img v-if="!rem.member1.contact.photo" src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                <!-- member2 -->
                <img v-if="rem.member2.contact.photo" v-bind:src="urlUploads + rem.member2.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                <img v-if="!rem.member2.contact.photo" src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                <!-- date + other member name -->
                <div class="d-flex align-items-center justify-content-between py-1">
                  <h6 class="mt-2 text-left text-white pt-2" style="padding-left: 2rem;">
                    R.E.M du {{ rem.date|formatDate }}
                    <div v-if="userIsMember1">avec {{ rem.member2.contact.firstName }} {{ rem.member2.contact.lastName }}</div>
                    <div v-if="!userIsMember1">avec {{ rem.member1.contact.firstName }} {{ rem.member1.contact.lastName }}</div>
                  </h6>
                  <span></span>
                  <a href="#" v-if="userIsMember1" class="ps-3" data-bs-toggle="modal" data-bs-target="#modalUpdateRem"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
                </div>
              </div>
                  
              <!-- modal update rem -->
              <div class="add-new-contact-modal modal fade px-0" id="modalUpdateRem" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="d-flex align-items-center justify-content-between">
                        <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalRem" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <Form :type="formType" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="rem.image" class="mt-3 mb-3">
              <img v-bind:src="this.$urlUploads + rem.image" class="img-fluid" alt="image">
          </div>

        </div>
      </div>

      <div v-if="rem.photoThumb" class="container">
        <div class="card mb-3">
          <div class="card bg-rbo rounded">
            <div class="card-body">
              <img v-bind:src="this.$urlUploads + rem.photoThumb" class="img-fluid" alt="image">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/RemForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Rem',
  components: {
    Header,
    Footer,
    Form
  },
  data: function() {
    return {
      formType: '',
      rem: [],
      userData: [],
      userIsMember1: true,
      id: this.$route.params.id,
      urlUploads: this.$urlUploads,
      urlWeb: this.$urlWeb,
    }
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    await UserService.getRem(this.$route.params.id).then((response) => {
        this.rem = response.data;
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.userData.member.id != this.rem.member1.id) {
      this.userIsMember1 = false;
    }
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.add-new-contact-wrap a {
  background-color: #153760;
}
</style>