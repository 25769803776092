class RboService {
  getLabelFonction(role) {
    const roles = {
      'DDD': "Directeur des développements",
      'CDG': "Comité Directeur du Groupe",
      'P': "Président(e)",
      'Pm': "Président",
      'Pf': "Présidente",
      'VP': "Président(e) adjoint(e)",
      'VPm': "Président adjoint",
      'VPf': "Présidente adjointe",
      'S': "Secrétaire",
      'Acc': "Accueil"
    }
    return roles[role];
  }
  setMembersForm(members) {
    const options = {};
      for ( var member in members) {
        options.push({'id': member.id, 'value': member.contact.firstName});
      }
    return options;
  }
}

export default new RboService();
