<template>
  <div>

    <Header title="Merci CA" />

        <div class="page-content-wrapper">

      <div class="container">
         <Form :type="formType" />
      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/MerciCaForm.vue'

import UserService from '../services/user.service';

export default {
  name: 'MerciCaForm',
  components: {
    Header,
    Footer,
    Form
  },
  data () {
    return {
      formType: '',
      userData: [],
      userIsMember1: true,
      formSelectMembers: [],
      error: null,
      merciCa: []
    }
  },
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
        await UserService.getMembersOtherForm().then(
        response => {
          localStorage.setItem('membersForm', JSON.stringify(response.data));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    this.userGroupMembers = JSON.parse(localStorage.getItem('membersForm'));

    const lsMembersForm = JSON.parse(localStorage.getItem('membersForm'));
    if( lsMembersForm ) { this.formSelectMembers = lsMembersForm['hydra:member']; }    
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

