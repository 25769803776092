<template>
  <div>

    <Header :title=title />

    <div class="page-content-wrapper">

      <div class="container">
        <div class="card">
          <div class="card-body">
            <div class="minimal-tab">
              <ul class="nav nav-tabs mb-3" id="affanTab2" role="tablist">
                <li v-if="loggedIn" class="nav-item" role="presentation">
                  <button v-bind:class="loggedIn?'btn active':'btn'" id="myGroup-tab" data-bs-toggle="tab" data-bs-target="#myGroup" type="button" role="tab" aria-controls="myGroup" aria-selected="true">Mon groupe</button>
                </li>
                <li v-if="loggedIn && isMember" class="nav-item" role="presentation">
                  <button class="btn" id="reunions-tab" data-bs-toggle="tab" data-bs-target="#reunions" type="button" role="tab" aria-controls="reunions" aria-selected="false">Réunions</button>
                </li>
                <li v-if="userGroups.total > 0" class="nav-item" role="presentation">
                  <button class="btn" id="list-tab" data-bs-toggle="tab" data-bs-target="#list" type="button" role="tab" aria-controls="list" aria-selected="false">Autres groupes</button>
                </li>
                <li v-if="!loggedIn" class="nav-item" role="presentation">
                  <button v-bind:class="!loggedIn?'btn active':'btn'" id="map-tab" data-bs-toggle="tab" data-bs-target="#map" type="button" role="tab" aria-controls="map" aria-selected="false">Carte</button>
                </li>
              </ul>
              <div class="tab-content rounded" id="affanTab2Content">

                <!-- Mon groupe -->
                <div v-if="loggedIn" v-bind:class="loggedIn?'tab-pane show active':'tab-pane'" id="myGroup" role="tabpanel" aria-labelledby="myGroup-tab">
                  <div class="container mx-0 px-0 mb-3">
                    <div v-if="isMember">
                      <div class="card bg-rbo rounded-0 rounded-top">
                        <div class="card-body py-3">
                          <h6 class="mb-0 text-white line-height-1">Mon groupe</h6>
                        </div>
                      </div>
                      <div class="card rounded-0 rounded-bottom">
                        <ul class="list-group">
                          <li class="list-group-item">
                            <a v-bind:href="'/groups/' + userData.groupe.id">            
                            <strong>{{userData.groupe.name}}</strong></a>
                          </li>
                          <li v-if="userData.groupe.reunionJour" class="list-group-item">Réunion chaque {{userData.groupe.reunionJour}} à {{userData.groupe.reunionHeure}}</li>
                          <li v-else class="list-group-item">Réunion : <span class="badge bg-warning text-dark">Informations non renseignées</span></li>

                          <li v-if="userData.groupe.urlJitsi" class="list-group-item">Lien Visio : <a :href="userData.groupe.urlJitsi" target="_blank">{{userData.groupe.urlJitsi}}</a></li>
                          <li v-else class="list-group-item">Lien Visio : <span class="badge bg-warning text-dark">Non renseigné</span></li>
                          
                          <li class="list-group-item"><SocialNetwork :f="group.facebook" :i="group.instagram" :l="group.linkedIn" /></li>
                        </ul>
                      </div>
                    </div>
                    <div v-else class="card bg-danger rounded-0 rounded">
                      <div class="card-body text-center py-3">
                        <h6 class="mb-0 text-white line-height-1">Membre d'aucun groupe</h6>
                      </div>
                    </div>
                  </div>

                  <!-- Membres du groupe -->
                  <div v-if="isMember">
                      <div class="card bg-rbo rounded-0 rounded-top">
                        <div class="card-body py-3">
                          <h6 class="mb-0 text-white line-height-1">Les membres de mon groupe</h6>
                        </div>
                      </div>
                      <div  v-if="members.length > 0" class="card rounded-0 rounded-bottom">
                        <MembersList v-bind:members="members" v-bind:urlUploads="urlUploads" />
                      </div>
                      <div v-else class="card bg-danger rounded-0 rounded mt-2">
                        <div class="card-body text-center py-3">
                          <h6 class="mb-0 text-white line-height-1">Aucun membre</h6>
                        </div>
                      </div>
                  </div>

                </div>

                <!-- Réunions (tab) -->
                <div v-if="loggedIn && isMember" class="tab-pane fade" id="reunions" role="tabpanel" aria-labelledby="reunions-tab">
                  <div class="container mx-0 px-0 mb-3">
                    <div v-if="reunions.length > 0 ">
                      <div class="card bg-rbo rounded-0 rounded-top">
                        <div class="card-body py-3">
                          <h6 class="mb-0 text-white line-height-1">Les 10 prochaines réunions</h6>
                        </div>
                      </div>
                      <div class="card rounded-0 rounded-bottom">
                        <ul class="ps-0 chat-user-list mb-0">
                          <li v-for="reunion in reunions" :key="reunion.id" class="p-3 mb-0 chat-unread">
                            <a class="d-flex" v-bind:href="'/reunions/' + reunion.id">
                              <div class="chat-user-info">
                                <h6 class="text-left text-truncate mb-0">Le {{reunion.date|formatDate}} à {{reunion.heureDebut}}</h6>
                                <div class="last-chat">
                                  <p v-if="reunion.ordreDuJour" class="text-left mb-0 text-truncate">Ordre du jour : {{reunion.ordreDuJour}}</p>
                                  <p v-if="reunion.isDone" class="text-left mb-0 text-truncate"><span class="badge bg-success">Clôturée</span></p>
                                </div>
                              </div>
                            </a>
                            <div class="dropstart chat-options-btn">
                              <a v-bind:href="'/reunions/' + reunion.id" class="text-rbo">
                                <i class="fas fa-chevron-right"></i>
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-else class="card bg-danger rounded-0 rounded">
                      <div class="card-body text-center py-3">
                        <h6 class="mb-0 text-white line-height-1">Aucune réunion</h6>
                      </div>
                    </div>
                  </div>

                </div>

                <!-- Groupes (tab) -->
                <div v-if="userGroups.total > 0" class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">

                  <!-- groupes (chat) -->
                  <ul class="ps-0 chat-user-list mb-0">
                    <li v-for="group in userGroups.groups" :key="group.id" class="p-3 mb-0 chat-unread">
                      <a class="d-flex" v-bind:href="'/groups/' + group.id">
                        <div class="chat-user-info">
                          <h6 class="text-left text-truncate mb-0">
                            <span v-if="group.statut == 'project'" class="badge bg-warning p-1" style="margin-right:.5em;font-size:0.5em;vertical-align:middle">P</span>
                            {{group.name}}
                            </h6>
                          <div class="last-chat">
                            <p class="text-left mb-0 text-truncate">
                              Réunion chaque {{group.reunionJour}} à {{group.reunionHeure}}
                              <!--<span class="badge rounded-pill bg-primary ms-2">2</span>-->
                            </p>
                          </div>
                        </div>
                      </a>
                      <div class="dropstart chat-options-btn">
                        <a v-bind:href="'/groups/' + group.id" class="text-rbo">
                          <i class="fas fa-chevron-right"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- Carte (tab) -->
                <div v-if="!loggedIn && isMarkersLoaded" v-bind:class="!loggedIn?'tab-pane show active':'tab-pane fade'" id="map" role="tabpanel" aria-labelledby="map-tab">
                  <h6>Un groupe RBO près de chez vous ?</h6>
                  <GoogleMap :gMapData=gMapData />
                  <div class="mt-2 mb-2 text-info">
                    <em>Cliquez sur un marqueur de la carte pour avoir plus d'informations...</em>
                  </div>
                  <div>
                    <a href="https://www.rbodeveloppement.com/rejoindre-un-groupe.html" target="_blank" class="btn p-2 btn-round btn-rbo">Je souhaite visiter un groupe</a>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="pb-3"></div>
    
    <Footer />

  </div>
</template>

<script>
import UserService from '../services/user.service';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MembersList from '@/components/MembersList.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import SocialNetwork from '@/components/SocialNetwork.vue'

export default {
  name: 'Groups',
  data: function() {
    return {
      userData: [],
      userGroups: [],
      members: [],
      reunions: [],
      group: [],
      facebook: null,
      title: "Groupes",
      role: null,
      isMember: true,
      isDDD: false,
      urlUploads: this.$urlUploads,
      loggedIn: this.$store.state.auth.status.loggedIn,
      isMarkersLoaded: null,
      gMapData: []
    }
  },
  components: {
    Header,
    Footer,
    MembersList,
    GoogleMap,
    SocialNetwork
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {

    // loggedIn
    if(this.$store.state.auth.status.loggedIn) {
      // userData
      if(localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'));

        // isMember
        if(this.userData.member.length == 0) { this.isMember = false; }

        // isDDD (or more)
        if( ['ROLE_DDD', 'ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isDDD = true;
        }

        // userGroups
        this.role = this.userData.user.role;
        if( this.isDDD ) {
            await UserService.getUserMyGroups().then(
              response => {
                localStorage.setItem('userGroups', response.data);
              },
              error => {
                this.content = (error.response && error.response.data) || error.message || error.toString();
              }
            );
          this.userGroups = JSON.parse(localStorage.getItem('userGroups'));
        }

        // members
        if ( this.isMember) {
          await UserService.getMembersOther().then(
            response => {
              localStorage.setItem('members', JSON.stringify(response.data));
            },
            error => {
              this.content = (error.response && error.response.data) || error.message || error.toString();
            }
          );
        }
        const lsMembers = JSON.parse(localStorage.getItem('members'));
        if( lsMembers ) { this.members = lsMembers['hydra:member']; }
      }

      if ( localStorage.getItem('userGroups') ) {
        this.userGroups = JSON.parse(localStorage.getItem('userGroups'));
        if( this.userGroups.total > 0 ) {
          this.title = "Mes groupes";
        }
      } else if (!this.isMember) {
        this.title = "Groupes";
      } else {
        this.title = "Mon groupe";
      }

      // les 8 prochaines réunions du groupe
      UserService.getReunions('?gId=' + this.userData.groupe.id).then(
        response => {
          this.reunions = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      )
    }
    else {
      this.title = "Groupes";
      if(!localStorage.getItem('mapGroups')) {
        await UserService.getGroupsMap().then(
          response => {
            localStorage.setItem('mapGroups', JSON.stringify(response.data['hydra:member']));
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
      if(localStorage.getItem('mapGroups')) {
        this.gMapData.zoom = 6;
        this.gMapData.markers = JSON.parse(localStorage.getItem('mapGroups'));
        this.isMarkersLoaded = true;
      }
    }
  }
}
</script>

<style>
.nav-item .btn {
  font-weight: 600;
}
.text-left {
  text-align: left;
}
</style>