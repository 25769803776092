<template>
  <div>
        <div class="card mb-3">
          <div class="card-body">

            <!-- merciCa form -->
            <div class="mt-3">
              <h6 class="mb-3 text-center text-rbo">{{ editing ? 'Modifier' : 'Enregistrer' }} un Merci CA</h6>

              <FormulateForm @submit="validationForm"
              >
                <FormulateInput
                    :options=formSelectMires
                    type="select"
                    name="mire"
                    placeholder="Selectionner une M.I.R.E à associer"
                    label="M.I.R.E"
                    v-model="merciCa.mire"
                />
                <FormulateInput
                    type="datepicker"
                    language="fr"
                    name="date"
                    label="Date"
                     format="dd/MM/yyyy"
                    :disabled-dates="tomorrow.disabledDates"
                    v-model="merciCa.date"
                />

                <div class="text-left mb-1"><label>De</label></div>
                <Multiselect 
                  v-model="merciCa.emetteur" 
                  :options="formSelectMember1"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectMember1.label"
                  label="label"
                  track-by="value"
                  placeholder="Sélectionner le nom du membre"
                  :internal-search="false"
                  @search-change="searchChangeMember1"
                ></Multiselect>

                <!--
                <FormulateInput
                    :options=formSelectMember1
                    type="autocomplete"
                    name="member1"
                    validation="required"
                    :validation-messages="{
                      required: 'Veuillez indiquer le nom du membre...'
                    }" 
                    label="De"
                    :value="userData.contact.fullName + ' (' + userData.groupe.name + ')'"
                    v-model="merciCa.emetteur"
                />
                -->

               <div class="text-left mb-3 mt-3">
                  <label class="text-left">Pour</label>
                </div>
                <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioGuest" v-model="radioBeneficiaire" value="isGuest">
                    <label class="form-check-label" for="infoRadioGuest">Invité</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioConfidential" v-model="radioBeneficiaire" value="isConfidential">
                    <label class="form-check-label" for="infoRadioConfidential">Confidentiel</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input  v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioInterGroup" v-model="radioBeneficiaire" value="isInterGroup">
                    <label class="form-check-label" for="infoRadioInterGroup">Inter groupe</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = false" class="form-check-input form-check-info" type="radio" id="infoRadioOldMember" v-model="radioBeneficiaire" value="isOldMember">
                    <label class="form-check-label" for="infoRadioOldMember">Ancien membre</label>
                  </div>
                  <div class="form-check text-left mb-3">
                    <input v-on:click="isSelectMemberShow = true" class="form-check-input form-check-info" type="radio" id="infoRadioMember" v-model="radioBeneficiaire" value="isMember">
                    <label class="form-check-label" for="infoRadioMember">Un membre du groupe</label>
                  </div>

                  <Multiselect 
                  v-if="isSelectMemberShow"
                  v-model="merciCa.beneficiaire" 
                  :options="formSelectMembers"
                  :searchable="true" 
                  :close-on-select="true" 
                  :show-labels="false" 
                  :custom-label="formSelectMembers.label"
                  label="label"
                  track-by="value"
                  placeholder="Sélectionner le nom du membre"
                  :internal-search="false"
                  @search-change="searchChangeMember2"
                ></Multiselect>

                <div v-if="isSelectMemberShow" class="mb-3"></div>

                <!--
                <FormulateInput
                    :options=formSelectMembers
                    type="autocomplete"
                    name="member2"
                    placeholder="Indiquer le nom du membre"
                    v-model="merciCa.beneficiaire"
                    v-if="isSelectMemberShow"
                />
                -->

                <!-- Checkbox de vueFormulate mais saffiche mal
                <FormulateInput
                  v-model="merciCa.isConfidential"
                  type="checkbox"
                  label="Confidentiel"
                />
                <FormulateInput
                  v-model="merciCa.isGuest"
                  type="checkbox"
                  label="Invité"
                />
                <FormulateInput
                  type="checkbox"
                  label="Ancien membre"
                  v-model="merciCa.isOldMember"
                />
                <FormulateInput
                  v-model="merciCa.isInterGroup"
                  type="checkbox"
                  label="Inter groupe"
                />
                -->
                <FormulateInput
                  type="number"
                  v-model="merciCa.ca"
                  label="CA €HT"
                  placeholder="Indiquer le chiffre d'affaires"
                  validation="required|number"
                  min="0"
                  step=".01"
                  :validation-messages="{
                      required: 'Veuillez indiquer le chiffre d\'affaires...'
                    }"
                />

                <div v-if="isSelectMemberShow" class="form-check">
                  <input v-model="merciCa.isEmailSend" class="form-check-input" id="checkboxEmail" type="checkbox" value="">
                  <label class="form-check-label" for="checkboxEmail">Envoyer une copie au bénéficiaire</label>
                </div>

                <div v-if="error" class="alert alert-warning w-100">
                  <i class="fas fa-exclamation-triangle"></i>
                  {{error}}
                </div>
                
                <FormulateInput
                  type="submit"
                  label="Valider"
                  id="buttonMerciCa"
                  input-class="btn btn-rbo w-100 text-white mt-3"
                />
                
              </FormulateForm>

            </div>

          </div>
        </div>

  </div>
</template>

<script>
import router from '../router'
import UserService from '../services/user.service';
//import axios from 'axios';
//import authHeader from '../services/auth-header';

var temp = Object.freeze({
      mire: '',
      date: '',
      emetteur: '',
      beneficiaire: '',
      isConfidential: false,
      isGuest: false,
      isOldMember: false,
      isInterGroup: false,
      isEmailSend: false,
    });

export default {
  name: 'Form',
  components: {
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      userData: [],
      formSelectMember1: [],
      formSelectMembers: [],
      formSelectMires: [],
      error: null,
      merciCa: Object.assign({}, temp),
      mires: [],
      editing: false,
      urlUploads: this.$urlUploads,
      tomorrow: null,
      radioBeneficiaire: null,
      isSelectMemberShow: false,
      member2Id: null,
      isBureau: false,
      isDDD: false,
      isRBO: false,
      mireId: '',
      formType: null,
    }
  },
  watch: {
  type(val) {
    if (val == 'add') {
      this.merciCa = Object.assign({}, temp);
    }
  },
  '$route.query.type': {
      handler: function(type) {
        this.formType = type;
        if(this.formType != null) {
        this.mireId = this.formType.slice(3);
        }
      },
      deep: true,
      immediate: true
    }
},
  async created() {
    this.userData = JSON.parse(localStorage.getItem('userData'));
        await UserService.getMembersOtherForm().then(
        response => {
          localStorage.setItem('membersForm', JSON.stringify(response.data));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );

    if(this.formType != null) {
      this.mireId = this.formType.slice(3);
    } 
    console.log(this.mireId.length)
    if(this.mireId.length === 0) {
      UserService.getMires('?mId=' + this.userData.member.id + '&t=r').then(
        response => {
          this.mires = response.data['hydra:member'];
          this.mires.forEach(mire => {
            if(mire.isDone === false) {
            this.formSelectMires.push({ value: mire.id, label: 'M.I.R.E du ' + new Date(mire.date).toLocaleDateString() + ' avec ' + mire.emetteur.contact.firstName + ' ' + mire.emetteur.contact.lastName});
            }
          });
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
    
    this.userGroupMembers = JSON.parse(localStorage.getItem('membersForm'));

    const lsMembersForm = JSON.parse(localStorage.getItem('membersForm'));
    if( lsMembersForm ) { this.formSelectMembers = lsMembersForm['hydra:member']; } 

      if(localStorage.getItem('userData')) {
        this.userData = JSON.parse(localStorage.getItem('userData'));
        // isBureau
        if( ['ROLE_BUREAU'].includes(this.userData.user.role) ) {
          this.isBureau = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isDDD
        else if( ['ROLE_DDD', 'ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isDDD = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isRBO or more
        else if( ['ROLE_RBO', 'ROLE_RBO_ADMIN', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN'].includes(this.userData.user.role) ) {
          this.isRBO = true;
          this.formSelectMember1 = lsMembersForm['hydra:member']
        }
        // isMember
        else {
          this.formSelectMember1 =[{ value: this.userData.member.id, label: this.userData.contact.fullName }];
        }
    }

    this.tomorrow = {
      disabledDates: {
        from: new Date(),
    },
    }
  },

  mounted() {
    if ("id" in this.$route.params) {
      this.getMerciCaById();
      this.editing = true;
    } else {
      this.merciCa.date = new Date();
      this.merciCa.emetteur = this.userData.member ? {value : this.userData.member.id, label: this.userData.contact.fullName + ' (' + this.userData.groupe.name + ')'} : '';
    }
    if(this.formType != null) {
      this.getMireById();
    } 
  },
  methods: {
    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },
    searchChangeMember1(search) {
      if(this.isBureau || this.isDDD || this.isRBO ) {
        this.formSelectMember1 = search
          ? (this.formSelectMember1.filter(option => this.normalizedContains(search, option.label)))
          : this.userGroupMembers['hydra:member'];
      } else {
         this.formSelectMember1 = search
        ? (this.formSelectMember1.filter(option => this.normalizedContains(search, option.label)))
        : [{ value: this.userData.member.id, label: this.userData.contact.fullName }];
      }
    },
    searchChangeMember2(search) {
      this.formSelectMembers = search
        ? (this.formSelectMembers.filter(option => this.normalizedContains(search, option.label)))
        : this.userGroupMembers['hydra:member'];
    },
    getMerciCaById() {
      UserService.getMerciCa(this.$route.params.id).then( 
          response => {
                    this.merciCa = response.data;
                    //this.merciCa.emetteur = this.userData.member.id;
                    this.merciCa.emetteur.label = this.merciCa.emetteur.contact.firstName + ' ' + this.merciCa.emetteur.contact.lastName + ' (' + this.merciCa.emetteur.groupe.name + ')';
                    this.merciCa.emetteur.value = this.merciCa.emetteur.id;
                    

                    if (this.merciCa.isConfidential == true){this.radioBeneficiaire = 'isConfidential'}
                    if (this.merciCa.isGuest == true){this.radioBeneficiaire = 'isGuest'}
                    if (this.merciCa.isOldMember == true){this.radioBeneficiaire = 'isOldMember'}
                    if (this.merciCa.isInterGroup == true){this.radioBeneficiaire = 'isInterGroup'}
                    if (this.merciCa.beneficiaire){
                      this.radioBeneficiaire = 'isMember'
                      //this.member2Id = this.merciCa.beneficiaire.id;
                      //this.merciCa.beneficiaire = this.merciCa.beneficiaire.contact.firstName + ' ' + this.merciCa.beneficiaire.contact.lastName + ' (' + this.merciCa.beneficiaire.groupe.name + ')';
                      
                      this.merciCa.beneficiaire.label = this.merciCa.beneficiaire.contact.firstName + ' ' + this.merciCa.beneficiaire.contact.lastName + ' (' + this.merciCa.beneficiaire.groupe.name + ')';
                      this.merciCa.beneficiaire.value = this.merciCa.beneficiaire.id; 
                    }
                    if(this.merciCa.mire) {
                      this.formSelectMires.push({ value: this.merciCa.mire.id, label: 'M.I.R.E du ' + new Date(this.merciCa.mire.date).toLocaleDateString() + ' avec ' + this.merciCa.mire.emetteur.contact.firstName + ' ' + this.merciCa.mire.emetteur.contact.lastName});
                    }
                    this.merciCa.mire = this.merciCa.mire ? this.merciCa.mire.id : null;
                },
          error => {this.content =(error.response && error.response.data) || error.message || error.toString();}
        );
    },
    getMireById() {
      UserService.getMire(this.mireId).then(
        response => {
          this.mires = response.data;
          if(this.mires.isDone === false) {
          this.formSelectMires = [{ value: this.mires.id, label: 'M.I.R.E du ' + new Date(this.mires.date).toLocaleDateString() + ' avec ' + this.mires.emetteur.contact.firstName + ' ' + this.mires.emetteur.contact.lastName}];
          this.merciCa.mire = this.mires.id;
          if(this.mires.emetteur) {
            console.log(this.mires.emetteur)
            this.radioBeneficiaire = 'isMember';
            this.formSelectMembers = [{ value: this.mires.emetteur.id, label: this.mires.emetteur.contact.firstName + ' ' + this.mires.emetteur.contact.lastName + ' (' + this.mires.emetteur.groupe.name + ')'}];
            this.merciCa.beneficiaire = { value: this.mires.emetteur.id, label: this.mires.emetteur.contact.firstName + ' ' + this.mires.emetteur.contact.lastName + ' (' + this.mires.emetteur.groupe.name + ')'};
          }

          }
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    },
    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
          let lsUserData = JSON.parse(localStorage.getItem('userData'));
          this.$store.dispatch('auth/setIsMember', lsUserData.member.length > 0 ? true : false);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    async validationForm() {
        if("id" in this.$route.params) {
            //var valueBeneficiaire = document.getElementsByName('member2');
            const merciCa = {
                date: this.merciCa.date,
                emetteur: "/api/members/" + this.merciCa.emetteur.value,
                beneficiaire: this.radioBeneficiaire == 'isMember' ? ("/api/members/" + this.merciCa.beneficiaire.value) : null,
                isConfidential: this.radioBeneficiaire == 'isConfidential' ? true : false,
                isGuest: this.radioBeneficiaire == 'isGuest' ? true : false,
                isOldMember: this.radioBeneficiaire == 'isOldMember' ? true : false,
                isInterGroup: this.radioBeneficiaire == 'isInterGroup' ? true : false,
                ca: this.merciCa.ca,
                isEmailSend: this.merciCa.isEmailSend,
                updatedBy: "/api/users/" + this.userData.user.id,
            };
             UserService.patchMerciCa(this.$route.params.id, merciCa).then(
                () => {
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
            );
            document.getElementById('buttonMerciCa').click();
            document.getElementById('closeModalMerciCa').click();
            router.push('/merciCas');
            this.reload();
        } else {
          //var valueMember2 = document.getElementsByName('member2');
          //var valueMember1 = document.getElementsByName('member1');
          
          const merciCa = {
                site: "/api/sites/" + this.userData.user.siteId,
                groupe: "/api/groupes/" + this.userData.groupe.id,
                mire: this.merciCa.mire ? "/api/mires/" + this.merciCa.mire : null,
                date: this.merciCa.date,
                emetteur: "/api/members/" + this.merciCa.emetteur.value,
                beneficiaire: this.radioBeneficiaire == 'isMember' ? ("/api/members/" + this.merciCa.beneficiaire.value) : null,
                isConfidential: this.radioBeneficiaire == 'isConfidential' ? true : false,
                isGuest: this.radioBeneficiaire == 'isGuest' ? true : false,
                isOldMember: this.radioBeneficiaire == 'isOldMember' ? true : false,
                isInterGroup: this.radioBeneficiaire == 'isInterGroup' ? true : false,
                ca: this.merciCa.ca,
                isEmailSend: this.merciCa.isEmailSend,
                createdBy: "/api/users/" + this.userData.user.id,
            };
            await UserService.postMerciCa(merciCa).then(
                res => {
                  let merciCaId = res.data['@id'];
                  this.merciCaNewId = merciCaId.slice(15);
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
                
            );
            router.push('/merciCas/' + this.merciCaNewId);
            this.reload();
        }
    },  
  }
}
</script>

<style>

</style>