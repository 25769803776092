<template>
  <div>

    <Header title="Merci CA" />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left"></i> Mon Espace Membre</a></li>
            <li class="breadcrumb-item"><a href="/merciCas">Liste des Merci CA</a></li>
          </ol>
        </nav>
      </div>
      
      <div class="container">
        <div class="card mb-3">
          <div class="card bg-rbo rounded-0 rounded-top">
            <div class="card-body py-3 pe-1">
                <div class="badge-avater-group">
                    <!-- member1 -->
                    <img v-if="merciCa.emetteur.contact.photo" v-bind:src="urlUploads + merciCa.emetteur.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                    <img v-if="!merciCa.emetteur.contact.photo" src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                    <!-- member2 -->
                    <img v-if="!merciCa.beneficiaire" src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                    <img v-else-if="merciCa.beneficiaire && merciCa.beneficiaire.contact.photo" v-bind:src="urlUploads + merciCa.beneficiaire.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                    <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                    <!-- date + other member name -->
                    <div class="d-flex align-items-center justify-content-between py-1">
                      <h6 class="mt-2 text-left text-white pt-2" style="padding-left: 2rem;">
                        Merci CA du {{ merciCa.date|formatDate }}
        
                      </h6>
                      <span></span>
                      <a href="#" v-if="merciCaIsEditable" class="ps-3" data-bs-toggle="modal" data-bs-target="#modalUpdateMerciCa"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
                    </div>
                  </div>

                <!-- modal update mercica -->
                  <div class="add-new-contact-modal modal fade px-0" id="modalUpdateMerciCa" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalMerciCa" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <Form :type="formType" />
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div class="mt-3 mb-1 ml-2 text-left">
            <div class="ml-2" style="margin-left:15px;">
              <p v-if="merciCa.mire" class="mb-0">
                Associé à la 
                <a v-bind:href="/mires/ + merciCa.mire.id">M.I.R.E du {{merciCa.mire.date|formatDate}}</a>
              </p>
            </div>
          </div>

          <div v-if="merciCa.image" class="mt-3 mb-3">
              <img v-bind:src="this.$urlUploads + merciCa.image" class="img-fluid" alt="image">
          </div>
        </div>
      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/MerciCaForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'MerciCa',
  components: {
    Header,
    Footer,
    Form
  },
  data: function() {
    return {
      formType: '',
      merciCa: [],
      userData: [],
      merciCaIsEditable: true,
      id: this.$route.params.id,
      urlUploads: this.$urlUploads,
    }
  },
   filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    await UserService.getMerciCa(this.$route.params.id).then((response) => {
        this.merciCa = response.data;
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.merciCa.emetteur.id != this.userData.member.id) {
      this.merciCaIsEditable = false;
    }
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.add-new-contact-wrap a {
  background-color: #153760;
}
</style>