<template>
  <div>

    <!-- Footer Nav -->
    <div class="footer-nav-area" id="footerNav">
      <div class="container px-0">
        <!-- Footer Content -->
        <div class="footer-nav position-relative">
          <ul class="h-100 d-flex align-items-center justify-content-between ps-0">
            <li>
              <a href="/"><i class="fas fa-home fa-2x"></i><span>Accueil</span></a>
            </li>
            <li v-if="!loggedIn">
              <a href="/groups"><i class="fas fa-users fa-2x"></i><span>Groupes</span></a>
            </li>
            <li v-if="!loggedIn">
              <a href="/login"><i class="fas fa-sign-in-alt fa-2x"></i><span>Connexion</span></a>
            </li>
            <li v-if="loggedIn && isMember">
              <a href="/groups"><i class="fas fa-users fa-2x"></i><span>Mon groupe</span></a>
            </li>
            <li v-if="loggedIn && !isMember">
              <a href="/groups"><i class="fas fa-users fa-2x"></i><span>Groupes</span></a>
            </li>
            <li v-if="loggedIn">
              <a href="/members"><i class="fa fa-street-view fa-2x" aria-hidden="true"></i><span>Annuaire</span></a>
            </li>
            <li v-if="loggedIn">
              <a href="/interactions"><i class="fas fa-comments fa-2x"></i><span>Interactions</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isMember: true,
      loggedIn: this.$store.state.auth.status.loggedIn,
    }
  },
  created() {
    if(localStorage.getItem('userData')) {
      this.userData = JSON.parse(localStorage.getItem('userData'));
      if(this.userData.member.length == 0) { this.isMember = false; }
    }
  }
}
</script>
