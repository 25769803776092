import axios from 'axios';
import api from './api.js'
//import authHeader from './auth-header';

const urlApi = "https://dev.rbodev.fr/api/";

class UserService {

  getLastVersion() {
    return api.get(urlApi + 'lastPwa');
  }

  getUserMe() {
    return api.get(urlApi + 'me');
  }

  getUserMyGroups() {
    return api.get(urlApi + 'myGroups');
  }

  getGroupsMap() {
    // axios (not api) because auth not needed
    return axios.get(urlApi + 'groupes?q=map');
  }

  getChangeGroup(memberId, groupId) {
    return api.get(urlApi + 'changeGroup/mId/' + memberId + '/gId/' + groupId);
  }

  getGroup(id) {
    return api.get(urlApi + 'groupes/' + id);
  }

  // Members
  getMembersOther() {
    return api.get(urlApi + 'members?q=other');
  }

  getMembersOtherForm() {
    return api.get(urlApi + 'members?q=otherForm');
  }

  getMembersOtherGroup(filters) {
    return api.get(urlApi + 'members' + filters);
  }

  getMembersSearch(search) {
    return api.get(urlApi + 'members?search=' + search);
  }

  getMember(id) {
    return api.get(urlApi + 'members/' + id);
  }

  // Profile > Contact + Organisme
  patchContact(id, data) {
    return api.patch(urlApi + 'contacts/' + id, data);
  }

  patchOrganisme(id, data) {
    return api.patch(urlApi + 'organismes/' + id, data);
  }

  // Rem
  getRems(filters) {
    return api.get(urlApi + 'rems' + filters);
  }

  getRem(id) {
    return api.get(urlApi + 'rems/' + id);
  }

  postRem(data) {
    return api.post(urlApi + 'rems', data);
  }

  patchRem(id, data) {
    return api.patch(urlApi + 'rems/' + id, data);
  }

  // Mire
  getMires(filters) {
    return api.get(urlApi + 'mires' + filters);
  }

  getMire(id) {
    return api.get(urlApi + 'mires/' + id);
  }

  postMire(data) {
    return api.post(urlApi + 'mires', data);
  }

  patchMire(id, data) {
    return api.patch(urlApi + 'mires/' + id, data);
  }

  // MerciCa
  getMerciCas(filters) {
    return api.get(urlApi + 'merci_cas' + filters);
  }

  getMerciCa(id) {
    return api.get(urlApi + 'merci_cas/' + id);
  }

  postMerciCa(data) {
    return api.post(urlApi + 'merci_cas', data);
  }

  patchMerciCa(id, data) {
    return api.patch(urlApi + 'merci_cas/' + id, data);
  }

  // Reunion event: en fonction du filtre, la même requête pour: guest, replace, absent
  getReunionEvents(filters) {
    return api.get(urlApi + 'reunion_events' + filters);
  }

  getReunionEvent(id) {
    return api.get(urlApi + 'reunion_events/' + id);
  }

  postReunionEvent(data) {
    return api.post(urlApi + 'reunion_events', data);
  }

  // Reunions
  getReunions(filters) {
    return api.get(urlApi + 'reunions' + filters);
  }

  getReunion(id) {
    return api.get(urlApi + 'reunions/' + id);
  }

}

export default new UserService();
