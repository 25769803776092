<template>
  <div>

    <Header title="Accueil" />

    <div class="page-content-wrapper">

      <div class="container">
        <div class="card mb-3">
          <div class="card-body">

            <!-- logo -->
            <div class="pb-3">
              <img src="/img/rbo/logo.png" class="img-fluid" alt="logo">
            </div>

            <div class="mb-3 pb-3"></div>

            <!-- login btn -->
            <div class="d-grid gap-2 text-center pb-3">
              <a v-if="!loggedIn" class="btn btn-rbo btn-lg mb-3" href="/login">Connexion</a>
              <a v-if="!loggedIn" class="btn btn-secondary btn-lg mb-3" href="https://www.rbodev.fr/reset-password?r=app">Mot de passe oublié</a>
              <a v-if="loggedIn" class="btn btn-rbo btn-lg mb-3" href="/profile">Mon Espace Membre</a>
              <button v-if="loggedIn && isNewVersion" class="btn btn-warning  btn-lg mb-3" type="button" data-bs-toggle="modal" data-bs-target="#modalNewVersion">Nouvelle version disponible</button>
              <a v-if="loggedIn" class="btn btn-secondary btn-lg mb-3" @click.prevent="logOut" href="/home">Déconnexion</a>
            </div>

            <div class="pb-3"></div>

            <!-- intro -->
            <div class="text-justify">
              Cette application est mise à disposition des membres de
              <strong>RBO Développement</strong>, un Réseau de mise en relation par le Bouche à Oreille.
              <!--Nous avons tous besoin de relais pour accélerer le développement de nos activités.-->
            </div>

            <!-- Modal new version -->
            <div v-if="loggedIn && isNewVersion" class="modal fade" id="modalNewVersion" tabindex="-1" aria-labelledby="modalNewVersionLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h6 class="modal-title" id="modalNewVersionLabel">Nouvelle version disponible</h6>
                    <button class="btn btn-close p-1 ms-auto" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <p class="mb-2 text-left">
                      La version {{newVersion.code}} "{{newVersion.name}}" est disponible (version actuelle : {{this.$version}}).
                    </p>
                    <p class="mb-3 text-left">
                      Cette version propose les évolutions suivantes :
                    </p>
                    <div class="mb-2 text-left evolutionText" v-html="newVersion.detail"></div>
                  </div>
                  <div class="modal-footer">
                    <button class="btn btn-sm btn-secondary" type="button" data-bs-dismiss="modal">Annuler</button>
                    <button @click="activateNewVersion" class="btn btn-sm btn-rbo text-white" type="button">Activer la nouvelle version</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- tips -->
      <!--
      <div class="container direction-rtl">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row g-3">
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><i class="bi bi-emoji-smile"></i></div>
                  <div><strong>Humanité</strong></div>
                </div>
              </div>
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><i class="bi bi-shield-check"></i></div>
                  <div><strong>Confiance</strong></div>
                </div>
              </div>
              <div class="col-4">
                <div class="feature-card mx-auto text-center">
                  <div class="card mx-auto bg-gray"><i class="bi bi-bar-chart-line-fill"></i></div>
                  <div><strong>Croissance</strong></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->

      <div class="pb-3"></div>

    </div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Home',
  data: function() {
    return {
      loggedIn: this.$store.state.auth.status.loggedIn,
      isNewVersion: false,
      newVersion: null,
    }
  },
  components: {
    Header,
    Footer
  },
  async created() {
    if(this.loggedIn) {
      // last version
      await UserService.getLastVersion().then((response) => {
          let lastVersion = JSON.parse(response.data);
          if (lastVersion && lastVersion.code.slice(2) != this.$version.slice(2)) {
            this.isNewVersion = true;
            this.newVersion = lastVersion;
          }
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.go();
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    activateNewVersion() {
      const staticCacheName = 'cache-v' + this.$version;
      const dynamicCacheName = 'runtimeCache-v' + this.$version;
      caches.delete(staticCacheName).then(function() {});
      caches.delete(dynamicCacheName).then(function() {});
      this.$router.go();
    }

  }
}
</script>

<style>
.text-justify {
  text-align: justify;
}
.avatar-img {
  width: 100%;
  height: 100%;
  max-height: 80px;
  max-width: 80px;
  vertical-align: middle;
  -o-object-fit: cover;
  object-fit: cover;
}
.rounded-circle {
  border-radius: 50% !important;
}
.evolutionText ul li {
  list-style: square;
  text-decoration: none;
}
.evolutionText p {
  margin-top: 1rem;
}
</style>
