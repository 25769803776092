<template>
  <div>

    <Header title="M.I.R.E" />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left"></i> Mon Espace Membre</a></li>
            <li class="breadcrumb-item"><a href="/miresR">Liste des M.I.R.E</a></li>
          </ol>
        </nav>
      </div>
      
      <div class="container">
        <div class="card mb-3">
          <div class="card bg-rbo rounded-0 rounded-top">
            <div class="card-body py-3 pe-1">
                <div class="badge-avater-group">
                    <!-- member1 -->
                    <img v-if="mire.emetteur.contact.photo" v-bind:src="urlUploads + mire.emetteur.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                    <img v-if="!mire.emetteur.contact.photo" src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">
                    <!-- member2 -->
                    <img v-if="mire.beneficiaire && mire.beneficiaire.contact.photo" v-bind:src="urlUploads + mire.beneficiaire.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                    <img v-if="!mire.beneficiaire" src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
                    <!-- date + other member name -->
                    <div class="d-flex align-items-center justify-content-between py-1">
                      <h6 class="mt-2 text-left text-white pt-2 pe-3" style="padding-left: 2rem;">
                        M.I.R.E du {{ mire.date|formatDate }}
        
                      </h6>
                      <span></span>
                      <a href="#" v-if="mireIsEditable" class="ps-3" data-bs-toggle="modal" data-bs-target="#modalUpdateMire"><i class="card-btn fas fa-pencil-alt text-white"></i></a>
                    </div>
                  </div>

                <!-- modal update mire -->
                  <div class="add-new-contact-modal modal fade px-0" id="modalUpdateMire" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalMire" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <Form :type="formType" />
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
          <div class="mt-3 mb-1 ml-2 text-left">
            <p class="ml-2" style="margin-left:15px;">
              <span>Statut : </span>
              <span v-if="mire.statut == 'issued'" class="badge bg-secondary ml-2">Émise </span>
              <span v-if="mire.statut == 'sent'" class="badge bg-primary ml-2">Envoyée </span>
              <span v-if="mire.statut == 'transformed'" class="badge bg-success ml-2">Transformée </span>
              <span v-if="mire.statut == 'canceled'" class="badge bg-warning ml-2">Sans suite </span>
            </p>
            <div class="ml-2" style="margin-left:15px;margin-right:15px;">
              <p v-if="mire.merciCa" class="mb-0">
                Transformée en
                <a v-bind:href="/merciCas/ + mire.merciCa.id">Merci CA du {{mire.merciCa.date|formatDate}}</a>
              </p>
              <p v-if="!mire.merciCa && mire.statut != 'canceled' && isBeneficiaire" class="mb-0 d-flex justify-content-between">
                <button v-on:click="canceled" class="btn btn-sm bg-warning text-white p-1">Classer sans suite</button>
                <button v-on:click="transformed" class="btn btn-sm bg-success text-white p-1">Transformer en Merci CA</button>
              </p>
            </div>
          </div>

          <div v-if="mire.image" class="mt-3 mb-3">
              <img v-bind:src="this.$urlUploads + mire.image" class="img-fluid" alt="image">
          </div>
        </div>
      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/MireForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'Mire',
  components: {
    Header,
    Footer,
    Form
  },
  data: function() {
    return {
      formType: '',
      mire: [],
      userData: [],
      mireIsEditable: true,
      isBeneficiaire: false,
      id: this.$route.params.id,
      urlUploads: this.$urlUploads,
    }
  },
   filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    }
  }, 
  async created() {
    await UserService.getMire(this.$route.params.id).then((response) => {
        this.mire = response.data;
        console.log(this.mire)
      },
      error => {
        this.content = (error.response && error.response.data) || error.message || error.toString();
      }
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if (this.mire.emetteur.id != this.userData.member.id) {
      this.mireIsEditable = false;
      this.isBeneficiaire = true;
    }
  },
  methods: {
    async reload() {
      await UserService.getUserMe().then(
        response => {
          localStorage.setItem('userData', response.data);
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
      this.$router.go();
    },
    canceled: function() {
      const mire = {
        statut: 'canceled',
        isDone: true,
        updatedBy: "/api/users/" + this.userData.user.id,
      }
      UserService.patchMire(this.$route.params.id, mire).then(
                () => {
                    UserService.getUserMe().then(
                        response => {
                            localStorage.setItem('userData', response.data);
                        },
                        error => {
                            this.content = (error.response && error.response.data) || error.message || error.toString();
                        }
                    );
                },
      );
      this.reload();
    },
    transformed: function() {
      this.$router.push('/merciCas/formulaire?type=add' + this.mire.id);
    },
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.add-new-contact-wrap a {
  background-color: #153760;
}
</style>