<template>
  <div>

    <Header title="Membre" />

    <div class="page-content-wrapper">

      <div class="container">
        <div class="card mb-3 user-info-card">
          <div class="card-body d-flex align-items-center">
            <div class="user-profile me-3">
              <img v-if="contact.photo" v-bind:src="this.$urlUploads + contact.photo" class="avatar-img rounded-circle" alt="photo">
              <img v-else src="/img/avatar.png" class="avatar-img rounded-circle" alt="photo">
            </div>
            <div class="user-info">
              <div class="d-flex">
                <h5 class="mb-1">{{contact.firstName}} {{contact.lastName}}</h5>
              </div>
              <p class="d-flex mb-0"><strong v-if="organisme.enseigne">{{organisme.enseigne}} - &nbsp;</strong><strong>{{organisme.name}}</strong></p>
              <p class="d-flex mb-0">{{contact.jobTitle}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- group -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Son groupe</h6>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><strong>{{groupe.name}}</strong></li>
          </ul>
        </div>
      </div>

      <!-- company -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Son entreprise</h6>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">
            <li class="list-group-item"><strong>{{organisme.name}}</strong></li>
            <li v-if="organisme.enseigne" class="list-group-item text-justify">
              Enseigne :
              <span>{{organisme.enseigne}}</span>
              </li>
            <li class="list-group-item text-justify">
              Activités :
              <span v-if="organisme.detail">{{organisme.detail}}</span>
              <span v-if="!organisme.detail" class="badge bg-warning text-dark">Non renseigné</span>
            </li>
            <li class="list-group-item">
              Site web : 
              <a v-if="organisme.siteWeb" :href="organisme.siteWeb" target="_blank">{{organisme.siteWeb}}</a>
              <span v-if="!organisme.siteWeb" class="badge bg-warning text-dark">Non renseigné</span>
            </li>
          </ul>
        </div>
      </div>

      <!-- infos -->
      <div class="container mb-3">
        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Ses coordonnées</h6>
          </div>
        </div>
        <div class="card rounded-0 rounded-bottom">
          <ul class="list-group">

            <!-- email -->
            <li class="list-group-item d-flex align-items-center justify-content-between">
              <div>
                <strong>Email : </strong>
                <strong v-if="contact.email">{{ contact.email }}</strong>
                <span v-if="!contact.email" class="badge bg-warning text-dark">Non renseigné</span>
              </div>
              <div v-if="contact.email">
                <a v-bind:href="'mailto:' + contact.email">
                <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;"><i class="fas fa-envelope"></i></span></a>
              </div>
            </li>

            <!-- mobilePhone -->
            <li class="list-group-item d-flex align-items-center justify-content-between">
              <div>
                <strong>Mobile : </strong>
                <strong v-if="contact.mobilePhone">{{ contact.mobilePhone }}</strong>
                <span v-if="!contact.mobilePhone" class="badge bg-warning text-dark">Non renseigné</span>
              </div>
              <div v-if="contact.mobilePhone">
                <a v-bind:href="'tel:' + contact.mobilePhone">
                  <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;"><i class="fas fa-phone-alt"></i></span></a>
                <a v-bind:href="'sms:' + contact.mobilePhone">
                  <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;">SMS</span></a>
              </div>
            </li>

            <!-- officePhone -->
            <li class="list-group-item d-flex align-items-center justify-content-between">
              <div>
                <strong>Téléphone : </strong>
                <strong v-if="contact.officePhone">{{ contact.officePhone }}</strong>
                <span v-if="!contact.officePhone" class="badge bg-warning text-dark">Non renseigné</span>
              </div>
              <div v-if="contact.officePhone">
                <a v-bind:href="'tel:' + contact.officePhone">
                <span class="badge bg-rbo rounded-pill py-2" style="margin-left: 1rem; padding: 0 1rem;"><i class="fas fa-phone-alt"></i></span></a>
              </div>
            </li>

          </ul>
        </div>
      </div>

    </div>
    <div class="pb-3"></div>
    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import UserService from '../services/user.service';

export default {
  name: 'Member',
  data: function() {
    return {
      member: [],
      groupe: [],
      contact: [],
      organisme: [],
      userGroupMembers: [],
      urlUploads: this.$urlUploads,
    }
  },
  components: {
    Header,
    Footer
  },
  async created() {
    await UserService.getMember(this.$route.params.id).then((response) => {
        localStorage.setItem('member', JSON.stringify(response.data));
        this.member = response.data;
        this.groupe = this.member.groupe;
        this.contact = this.member.contact;
        this.organisme = this.contact.organisme;
        
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
  },
  beforeMount() {
    
  },
  mounted() {
    
  }
}
</script>