<template>
  <div class="mt-3">
    <gmap-map
      :zoom="gMapData.zoom"
      :center="gMapData.center"
      :style="gMapData.style"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false
      }"
    >
      <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      </gmap-info-window>

      <gmap-marker
        :key="index"
        v-for="(m, index) in gMapData.markers"
        :position="m.position"
        :clickable="true"
        @click="toggleInfoWindow(m,index)"
      ></gmap-marker>

    </gmap-map>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  props: {
    'gMapData': Array
  },
  data() {
    return {
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    };
  },
  async created() {
    if(!this.gMapData.zoom) { this.gMapData.zoom = 1; }
    if(!this.gMapData.center) { this.gMapData.center = {lat: 47.60, lng: -1.00}; }
    if(!this.gMapData.style) { this.gMapData.style = "width:100%; height:340px;"; }
  },
  methods: {
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;
      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    }
  }
}
</script>