<template>
  <div>

    <Header :title=headerTitle />

    <div class="page-content-wrapper">

      <div class="container">

        <form class="mb-3 border-bottom" @submit.prevent="submitSearch">
          <div class="input-group">
            <input class="form-control form-control-clicked" @input="restartSearch" type="search" name="search" v-model="search" placeholder="Rechercher un membre...">
            <button v-if="!isSearch" class="btn btn-rbo text-white" type="submit" style="width:3rem;"><i class="fas fa-search fz-14"></i></button>
            <button v-if="isSearch" @click.prevent="emptySearch" class="btn btn-rbo text-white" style="width:3rem;"><i class="fas fa-times fz-14"></i></button>            
          </div>
        </form>

        <div v-if="!isMember && !isSearch" class="card bg-danger rounded-0 rounded mb-3">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Membre d'aucun groupe</h6>
          </div>
        </div>

        <div v-if="isMember || isSearch" class="alert rounded mt-3" v-bind:class="members.length > 0 ? 'bg-rbo' : 'bg-warning'">
          <h6 class="mb-0 line-height-1" v-bind:class="members.length > 0 ? 'text-white' : 'text-dark'">{{ listTitle }}</h6>
        </div>

        <MembersList v-if="isMember || isSearch" v-bind:members="members" v-bind:isSearch="isSearch" v-bind:urlUploads="urlUploads" />

      </div>

    </div>

    <div class="pb-3"></div>

    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MembersList from '@/components/MembersList.vue'
import UserService from '../services/user.service';
//import _ from 'lodash';

export default {
  name: 'Members',
  data: function() {
    return {
      search: null,
      isSearch: false,
      members: [],
      userData: [],
      isMember: true,
      urlUploads: this.$urlUploads,
      loggedIn: this.$store.state.auth.status.loggedIn,
      headerTitle: "Membres",
      listTitle: "Les membres de mon groupe"
    }
  },
  components: {
    Header,
    Footer,
    MembersList
  },
  /*
  watch: {
    search: function() {
      this.debouncedSearch();
    }
  },
  */
  async created() {

    // userData + isMember
    this.userData = JSON.parse(localStorage.getItem('userData'));
    if(this.userData.member.length == 0) { this.isMember = false; }

    // groupe > other members
    if ( this.isMember) {
      await UserService.getMembersOther().then(
        response => {
          localStorage.setItem('members', JSON.stringify(response.data));
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
    }
    let lsMembers = JSON.parse(localStorage.getItem('members'));
    if( lsMembers ) { this.members = lsMembers['hydra:member']; }

    // search > members
    //this.debouncedSearch = _.debounce(this.submitSearch, 1000)
  },
  methods: {
    submitSearch: async function () {
      if(this.search.length > 0) {
        this.search = this.search.trim();
        this.isSearch = true;
        await UserService.getMembersSearch(this.search).then(
          response => {
            let total = response.data['hydra:totalItems'];
            this.listTitle = total + ' membre trouvé pour "' + this.search + '"';
            if(total > 1) {
              this.listTitle = total + ' membres trouvés pour "' + this.search + '"';
            }
            localStorage.setItem('memberSearch', JSON.stringify(response.data));
            this.members = response.data['hydra:member'];
          },
          error => {
            this.content = (error.response && error.response.data) || error.message || error.toString();
          }
        );
      }
    },
    restartSearch: function () {
      this.isSearch = false;
    },
    emptySearch: function () {
      this.search = null;
      this.isSearch = false;
      let lsMembers = JSON.parse(localStorage.getItem('members'));
      if( lsMembers ) { this.members = lsMembers['hydra:member']; }
    }
  }
}
</script>

<style>
.h6, h6 {
    font-size: 1rem;
}
.ps-1 {
    padding-left: 0.25rem!important;
}
.chat-user-list li .chat-user-thumbnail {
    width: 3rem;
    height: 3rem;
    -ms-flex: 0 0 3rem;
    flex: 0 0 3rem;
}
</style>