import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile.vue'
import Group from '../views/Group.vue'
import Groups from '../views/Groups.vue'
import GroupChoice from '../views/GroupChoice.vue'
import Reunion from '../views/Reunion.vue'
import Member from '../views/Member.vue'
import Members from '../views/Members.vue'
import Interactions from '../views/Interactions.vue'
import Rems from '../views/Rems.vue'
import RemForm from '../views/RemForm.vue'
import Rem from '../views/Rem.vue'
import Mires from '../views/Mires.vue'
import MireForm from '../views/MireForm.vue'
import Mire from '../views/Mire.vue'
import MerciCas from '../views/MerciCas.vue'
import MerciCaForm from '../views/MerciCaForm.vue'
import MerciCa from '../views/MerciCa.vue'
import Invites from '../views/Invites.vue'
import Invite from '../views/Invite.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/groups/:id',
    name: 'group',
    component: Group
  },
  {
    path: '/groups',
    name: 'groups',
    component: Groups
  },
  {
    path: '/groupChoice',
    name: 'groupChoice',
    component: GroupChoice
  },
  {
    path: '/reunions/:id',
    name: 'reunion',
    component: Reunion
  },
  {
    path: '/members',
    name: 'members',
    component: Members
  },
  {
    path: '/members/:id',
    name: 'member',
    component: Member
  },
  {
    path: '/interactions',
    name: 'interactions',
    component: Interactions
  },
  {
    path: '/rems/formulaire/:id',
    name: 'remEdit',
    component: RemForm
  },
  {
    path: '/rems/formulaire',
    name: 'remNew',
    component: RemForm
  },
  {
    path: '/rems',
    name: 'rems',
    component: Rems
  },
  {
    path: '/rems/:id',
    name: 'rem',
    component: Rem
  },
  {
    path: '/mires/formulaire/:id',
    name: 'mireEdit',
    component: MireForm
  },
  {
    path: '/mires/formulaire',
    name: 'mireNew',
    component: MireForm
  },
  {
    path: '/mires',
    name: 'mires',
    component: Mires
  },
  {
    path: '/miresG',
    name: 'miresG',
    component: Mires
  },
  {
    path: '/miresR',
    name: 'miresR',
    component: Mires
  },
  {
    path: '/mires/:id',
    name: 'mire',
    component: Mire
  },
  {
    path: '/merciCas/formulaire/:id',
    name: 'merciCaEdit',
    component: MerciCaForm
  },
  {
    path: '/merciCas/formulaire',
    name: 'merciCaNew',
    component: MerciCaForm
  },
  {
    path: '/merciCas',
    name: 'merciCas',
    component: MerciCas
  },
  {
    path: '/merciCas/:id',
    name: 'merciCa',
    component: MerciCa
  },
  {
    path: '/invites',
    name: 'invites',
    component: Invites
  },
  {
    path: '/invites/:id',
    name: 'invite',
    component: Invite
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/home', '/login', '/groups'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router