<template>
  <div>

    <Header :title=title />

    <div class="page-content-wrapper">

      <div class="container mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 py-2 px-3 rounded">
            <li class="breadcrumb-item"><a href="/profile"><i class="fas fa-chevron-left ml-2"></i> Mon Espace Membre</a></li>
          </ol>
        </nav>
      </div>

      <div class="container mb-3">
        <div class="card">
          <div class="card bg-rbo rounded-0 rounded-top">
            <div class="card-body py-3 text-center">
                  <h6 class="mb-0 text-white line-height-1">
                    CA €HT
                  </h6>
            </div>
          </div>

          <div class="mt-3 mb-1 text-rbo">
            <h4>{{lsUserData.merciCa.given.total|formatDecimal}} €HT</h4>
            <p v-if="lsUserData.merciCa.given.total > 0">de CA gagnés grâce à RBO !</p>
            <p v-else>de CA gagnés pour le moment !</p>
          </div>
        </div>
      </div>

      <div class="container mb-3">

        <div class="card bg-rbo rounded-0 rounded-top">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">{{merciCas.length == 0 ? '' : 'Mes ' + (merciCas.length > 30 ? '30' : merciCas.length) + ' derniers'}} Merci CA</h6>
          </div>
        </div>
        <ul v-if="merciCas.length > 0" class="ps-0 chat-user-list rounded-0">
          <li v-for="(merciCa, index) in merciCas" :key="merciCa.id" class="rounded-0 px-3 pt-2 pb-1 chat-unread text-left" v-bind:class="{ 'rounded-bottom': index === (merciCas.length-1) }">
            <a class="d-flex" v-bind:href="'/merciCas/' + merciCa.id">
              <div class="badge-avater-group">
                  <img v-if="merciCa.emetteur.contact.photo" v-bind:src="urlUploads + merciCa.emetteur.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="emetteur">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member1">

                  <img v-if="merciCa.beneficiaire && merciCa.beneficiaire.contact.photo" v-bind:src="urlUploads + merciCa.beneficiaire.contact.photo" class="avatar-img badge-avater badge-avater-lg" alt="beneficiaire">
                  <img v-else src="/img/avatar.png" class="avatar-img badge-avater badge-avater-lg" alt="member2">
              </div>
              
              <div class="chat-user-info" style="padding-left: 2rem;">
                <h6 v-if="lsUserData.member.id != merciCa.emetteur.id" class="mt-2 mb-0">Merci CA du {{merciCa.date|formatDate}} 
                  <br>de {{merciCa.emetteur.contact.firstName}} {{merciCa.emetteur.contact.lastName}}</h6>
                <h6 v-else-if="merciCa.beneficiaire" class="mt-2 mb-0">Merci CA du {{merciCa.date|formatDate}}
                  <br>pour {{merciCa.beneficiaire.contact.firstName}} {{merciCa.beneficiaire.contact.lastName}}</h6>
                <h6 v-else class="mt-2 mb-0">Merci CA du {{merciCa.date|formatDate}}</h6>

                <div class="last-chat">
                  <p class="mb-0">
                     <span v-if="lsUserData.member.id != merciCa.emetteur.id" class="badge bg-rbo">{{merciCa.ca}} €HT</span>
                     <span v-else class="badge bg-success">{{merciCa.ca}} €HT</span>
                  </p>
                  <p v-if="merciCa.mire" class="mb-0">
                    <a v-bind:href="/mires/ + merciCa.mire.id"><span class="badge bg-primary">M.I.R.E du {{merciCa.mire.date|formatDate}}</span></a>
                  </p>
                </div>
              </div>
            </a>
            <div class="dropstart chat-options-btn">
              <a v-bind:href="'/merciCas/' + merciCa.id" class="text-rbo">
                <i class="fas fa-chevron-right"></i>
              </a>
            </div>
          </li>
        </ul>
        <div v-else class="card bg-warning rounded-0 rounded mt-2">
          <div class="card-body text-center py-3">
            <h6 class="mb-0 text-white line-height-1">Aucun MERCI CA</h6>
          </div>
        </div>
        <div class="add-new-contact-wrap"><a class="shadow" href="#" data-bs-toggle="modal" data-bs-target="#newMerciCa"><i class="bi bi-plus-lg"></i></a></div>
          <div class="add-new-contact-modal modal fade px-0" id="newMerciCa" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addnewcontactlabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <button class="btn btn-close p-1 ms-auto me-0" type="button" id="closeModalMerciCa" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <Form :type="formType" />
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>

    <div class="pb-3"></div>
    <Footer />

  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Form from '@/components/MerciCaForm.vue'
import UserService from '../services/user.service';

export default {
  name: 'MerciCas',
  data: function() {
    return {
      merciCas: [],
      title: "Mes Merci CA",
      loggedIn: this.$store.state.auth.status.loggedIn,
      urlUploads: this.$urlUploads,
      formType: '',
      lsUserData: []
    }
  },
  components: {
    Header,
    Footer,
    Form
  },
  filters: {
    formatDate: function (value) {
      if (!value) return ''
      value = new Date(value).toLocaleDateString();
      return value;
    },
    formatDecimal: function (value) {
      if (!value) return '0'
      value = (value).toFixed(2);
      return value;
    },
  }, 
  async created() {
    this.lsUserData = JSON.parse(localStorage.getItem('userData'));
    UserService.getMerciCas('?mId=' + this.lsUserData.member.id).then(
        response => {
          this.merciCas = response.data['hydra:member'];
        },
        error => {
          this.content = (error.response && error.response.data) || error.message || error.toString();
        }
      );
  },
  watch: { 
   '$route.query.type': {
      handler: function(type) {
        this.formType = type;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style>
.nav-item .btn {
  font-weight: 600;
}
.text-left {
  text-align: left;
}
</style>